/* stylelint-disable */
/*md

## Typography Headings
### Headers

```html_example
<h1>Headline 1 example: is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h1>
<h2>Headline 2 example: is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h2>
<h3>Headline 3 example: is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h3>
<h4>Headline 4 example: is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h4>
<h5>Headline 5 example: is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h5>
<h6>Headline 6 example: is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h6>
```

*/
/* stylelint-enable */

.h1,
h1 {
    @include media-breakpoint-up(ls) {
        font-size: $fs-xxl;
    }
}

.h2,
h2 {
    @include media-breakpoint-up(ls) {
        font-size: $fs-xl;
    }
}

h2,
.h2,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    text-transform: uppercase;
}

.h3,
h3 {
    font-style: italic;

    @include media-breakpoint-up(ls) {
        font-size: $fs-xxl;
    }
}

.h4,
h4 {
    @include media-breakpoint-up(ls) {
        font-size: $fs-large;
    }
}

.h5,
h5 {
    @include media-breakpoint-up(ls) {
        font-size: $fs-medium;
    }
}

.h6,
h6 {
    @include media-breakpoint-up(ls) {
        font-size: $fs-base;
    }
}
