.breadcrumb {
    font-size: $fs-xxs;
    padding-bottom: 0;
    padding-left: 0;
    margin-bottom: $spacer-30;

    //.breadcrumb .breadcrumb-item
    &-item {
        a {
            color: $color-black-text;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
