.minicart {
    position: relative;

    &__title {
        // .minicart__title
        text-align: center;
        padding-left: $spacer-40;
        padding-right: $spacer-40;
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        @include media-breakpoint-up(ls) {
            padding-left: $spacer-50;
            padding-right: $spacer-50;
            min-height: 44px;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 40px);
    }

    &__content {
        flex: 1 0 auto;
    }

    &__btn {
        margin-bottom: 5px;
    }

    &__estimated-total {
        margin-bottom: $spacer-15;
        font-size: $fs-large;
        font-weight: fw(medium);
    }

    &__footer {
        flex: 0 0 auto;

        .paypal-button-container-mini {
            margin-top: 0;
        }

        .cart-action-checkout {
            margin-bottom: 5px;
        }
    }

    &__desc {
        // .minicart__desc
        text-align: center;
        margin-bottom: $spacer-30;
        padding-bottom: $spacer-30;
        border-bottom: 1px solid $color-gray;
    }

    &__cart-link {
        // .minicart__cart-link
        text-align: right;
        position: absolute;
    }

    &__list {
        // .minicart__list
        border: 0;
        padding: 0;
    }

    // checkout-options
    &__checkout-options {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
    }

    &__checkout-options-col {
        margin-bottom: $spacer-10;
        width: calc(50% - $spacer-05);
        overflow: hidden;
    }

    &__checkout-options-wrap {
        width: 100%;
        transition: opacity 0.15s;
        position: relative;
        overflow: hidden;

        &:hover {
            opacity: 0.7;
            cursor: pointer;
        }
    }

    &__hidden-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;

        &-fix {
            opacity: 0.001; // hack for chrome bug with iframe and overflow:hidden
        }
    }

    .bwp-checkout-button-container {
        margin: 10px 0px;
    }
}
