
$color-btn-paypal: #FFBA44;
$color-btn-amazon: #FDCF74;

//BWP Modifications: START
.bwp-checkout-button-container {
    .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    &.hide {
        display: none;
    }

    #bwp-cart-button {
        margin: 0px !important;
    }
}

.bwpBadge-container {
    .col-12 {
        width: auto !important;
    }
}

.bwpInventory-message {
    font-size: 10px;
    color: $color-red;
    width: 100%;
    margin-top: 5px;   
    
    &.hide {
        display: none;
    }
}

//BWP Modifications: END

.btn-unselectable {
    user-select: none;
    pointer-events: none;
    cursor: not-allowed;
}

.btn {
    text-align: center;
    min-width: 140px;

    &.disabled,
    &[disabled] {
        color: $color-gray;
        border-color: $color-silver-chalice;
        background-color: $color-gray-light;
        cursor: not-allowed;
    }
}

.btn-no-min-width {
    min-width: 0;
}

.btn-square {
    min-width: 44px;
    min-height: 44px;
    padding: $spacer-05;
}
.btn-circle {
    min-width: 44px;
    min-height: 44px;
    padding: $spacer-05;
    border-radius: 50%;
    background-color: transparent;
    border: black;
    border-width: 2px;
}

.btn-link {
    cursor: pointer;
    text-decoration: none;
    font-weight: fw(bold);
    min-width: 0;
    display: inline-block;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.btn-link-underlined {
    cursor: pointer;
    text-decoration: underline;
    min-width: 0;
    display: inline-block;
    text-underline-offset: 3px;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.btn-style-reset {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    min-width: 0;
    -webkit-appearance: none;
    appearance: none;
}

// <button class="btn-close js-menu-close-button" aria-label="${Resource.msg('label.button.close', 'common', null)}">
//     <i class="fi fi--close-button" aria-hidden="true"></i>
// </button>
.btn-close {
    @extend .btn-style-reset;

    .fi {
        font-size: $icon-size-xxl;

        @include media-breakpoint-up(ls) {
            font-size: $icon-size-xxxl;
        }
    }

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):hover {
        animation: $animation-half-spin;
    }
}

.icon-hover-effect {
    transition: opacity 0.2s, color 0.2s;
    opacity: 1;

    &:focus,
    &:hover {
        opacity: 0.6;
    }
}

a > .fi,
button > .fi {
    @extend .icon-hover-effect;
}

.btn-payment {
    width: 100%;
    height: 44px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 24px;

    &-google {
        background-image: url("../images/payment-logos/btn-google.svg");
        border-color: $color-black;
    }

    &-paypal {
        background-image: url("../images/payment-logos/btn-paypal.svg");
        border-color: $color-btn-paypal;
        background-color: $color-btn-paypal;
    }

    &-apple {
        background-image: url("../images/payment-logos/btn-apple.svg");
        border-color: $color-black;
        background-color: $color-black;
    }

    &-apple-native {
        width: 100%;
        margin-top: 0!important;
        height: 44px;
    }

    &-amazon {
        background-image: url("../images/payment-logos/btn-amazon.svg");
        border-color: $color-btn-amazon;
        background-color: $color-btn-amazon;
        background-position: center 11px;
    }
}

.link-bold-blue {
    color: blue;
    font-weight: fw(bold);

    &:hover {
        color: blue;
    }
}
