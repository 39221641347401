// ===================================
// FONTS
// ===================================
//
// This file gathers all fonts variables.
//

$fonts-root-path:   "../fonts/" !default;

// Font weights
$fw-light:     300 !default;
$fw-regular:   normal !default;
$fw-medium:    600 !default;
$fw-bold:      700 !default;
$fw-black:     900 !default;

// Usage: Function - fw(bold)
$font-weights: (
    light      : $fw-light,
    regular    : $fw-regular,
    medium     : $fw-medium,
    bold       : $fw-bold,
    black      : $fw-black
);

// Font bootstrap variables
$base-html-font-size:   16px !default;
$font-size-default:     $base-html-font-size !default;

// Font Spacing - letter-spacing
$ls-xxxl:               3px !default;
$ls-xxl:                2.4px !default;
$ls-xl:                 1.9px !default;
$ls-large:              1.6px !default;
$ls-medium:              1px !default;
$ls-normal:             normal !default;

// Values for creating letter spacing classes
$letter-spacings: (
    xxxl      : $ls-xxxl,
    xxl       : $ls-xxl,
    xl        : $ls-xl,
    large     : $ls-large,
    medium    : $ls-medium,
    normal    : $ls-normal
);

// Line Height - Body
$lh-base:               1.5 !default;   // 24px
$lh-medium:             1.25 !default;  // 20px
$lh-small:              1.2 !default;
$lh-xs:                 1 !default;     // 16px

// Values for creating line height classes
$line-heights: (
    base      : $lh-base,
    medium    : $lh-medium,
    small     : $lh-small,
    xs        : $lh-xs
);

// Font Size - Body
$base16-14px:           14px !default;

$fs-xxxl:               44px !default;
$fs-xxl:                32px !default;
$fs-xl:                 24px !default;
$fs-large:              20px !default;
$fs-medium:             18px !default;
$fs-base:               16px !default;
$fs-small:              14px !default;
$fs-xs:                 12px !default;
$fs-xxs:                11px !default;

// Values for creating font sizes classes
$font-sizes: (
    xxxl      : $fs-xxxl,
    xxl       : $fs-xxl,
    xl        : $fs-xl,
    large     : $fs-large,
    medium    : $fs-medium,
    base      : $fs-base,
    small     : $fs-small,
    xs        : $fs-xs,
    xxs       : $fs-xxs
);

// Font Spacing - letter-spacing
// TODO: init spaces, should be adapt during styling
$font-spacing-base:         0 !default;
$font-spacing-btn:          0.9px !default;
$font-spacing-input:        1px !default;
$font-spacing-label:        0.85px !default;
$font-spacing-placeholder:  $font-spacing-input !default;
$font-spacing-feedback:     $font-spacing-input !default;
