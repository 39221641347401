.pair-it-with-container {
    .pair-it-with-column {
        display: flex;
        justify-content: flex-end;
    }

    &.desktop{
        .pair-it-with {
            width: calc(100% - 85px);
            max-width: 700px;
        }
    }
}

.pair-it-with {
    margin-top: rem(40);

    &__title {
        text-transform: capitalize;
        font-style: normal;
    }

    .pair-it-with-products {
        display: flex;
        gap: 10px;
    }

    &__tile {
        box-shadow: $pair-it-with-shadow;
        border-radius: 10px;
        padding: rem(15);
        margin-bottom: rem(20);
        max-width: 70%;
        position: relative;

        &:hover {
            .quickview {
                opacity: 1;
            }
        }

        .quickview {
            opacity: 0;
            position: absolute;
            bottom: $spacer-70;
            right: $spacer-20;
            font-size: $icon-size-lg;
            transition: opacity 0.3s;

            @media (hover: none) {
                opacity: 1;
            }
        }

        .price {
            font-size: $fs-xs;
            //max-width: 30%;
            //margin-left: rem(10);

            &__old {
                margin-right: 0;
            }
        }

        .wishlist-tile {
            position: absolute;
            top: $spacer-20;
            right: $spacer-20;

            .icon-heart {
                width: $icon-size-lg;
            }
        }
    }

    &__tile-body {
        display: flex;
        justify-content: space-between;
        padding-top: rem(40);
        font-size: $fs-xs;
    }
}
