.idme-page {
    h2 {
        font-size: 32px;
        font-family: $font-family-secondary;
        font-weight: $fw-bold;
        text-transform: none;

        @include media-breakpoint-up(ls) {
            font-size: 42px;
        }
    }

    .idme-contents {
        margin: 0 auto;
        max-width: 1024px;
        text-align: center;
        width: 90%;

        @include media-breakpoint-up(ls) {
            width: 80%;
        }
    }
}

.idme-verify {
    text-align: center;
    margin: 20px 0 30px;

    .idme-header {
        font-size: $fs-xxl;
        font-weight: $fw-bold;
        font-style: normal;
        color: $color-black-text;
        letter-spacing: $ls-medium;
        margin: 0 auto 15px;
    }
    
    .idme-description {
        line-height: $lh-medium;
        font-size: $fs-small;
        color: $color-dove-gray;
        margin: 0 auto 15px;
        max-width: 280px;
        width: 80%;
    }
}

.idme-big-text {
    font-size: $fs-large;
    font-weight: $fw-regular;
    line-height: $lh-base;
    color: $color-black-text;
}

.idme-link {
    background-image: url('../images/bgicons/idme-verify-button.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin: 0 auto;
    min-height: 44px;
    min-width: 212px;
}

.idme-response-holder {
    &.m-not-applied {
        .idme-response-promo-not-applied {
            display: block;
        }
        .idme-response-promo-applied {
            display: none;
        }
    }
}

.idme-response-promo-not-applied {
    display: none;
}
