.remove-coupon {
    border: none;
    background: transparent;
}

.minicart__remove-coupon {
    border: none;
    background: transparent;
}

.coupon-price-adjustment + .coupon-price-adjustment {
    margin-top: 0.625rem;
}

.coupon-price-adjustment {
    background-color: $white;
    padding: 0.625em;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.1875rem;
}

.coupon-promotion-relationship {
    font-size: 0.813em;
    padding-left: 0;
    margin-bottom: 0;
}

.coupons-and-promos {
    margin-bottom: $spacer-20;

    & > div {
        margin: $spacer-05 0;
    }
}

.coupon-applied {
    color: $success;
    font-size: 0.813em;
}

.coupon-not-applied {
    color: $danger;
    font-size: 0.813em;
}

.coupon-missing-error {
    display: none;
}

.order-discount-total-color {
    color: $color-primary-alt;
}
