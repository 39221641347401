.icons-info {
    padding: $spacer-10;
    background-color: $color-additional;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    margin-bottom: $spacer-20;
    flex-wrap: wrap;

    &__item {
        // .icons-info__item
        text-align: center;
        max-width: 33%;
        width: 100%;

        &:nth-child(n+4) {
            margin-top: $spacer-20;

            &:last-child {
                margin-right: auto;
            }
        }
    }

    &__icon {
        // .icons-info__icon
        margin-bottom: $spacer-05;
        font-size: $fs-xxxl;
        line-height: $lh-xs;
    }

    &__text-container {
        // .icons-info__text-container
        font-size: $fs-xxs;

        @include media-breakpoint-up(ls) {
            font-size: $fs-small;
        }
    }

    &__title {
        // .icons-info__title
        font-weight: fw(bold);
        width: 100%;
        display: inline-block;
    }
}
