.add-to-cart-form {
    width: 100%;
    display: flex;
    margin-bottom: $spacer-20;

    &__qty {
        // .add-to-cart-form__qty
        margin-right: $spacer-20;
        margin-bottom: 0;
    }

    &__qty-select {
        // .add-to-cart-form__qty-select
        min-width: 65px;
    }

    &__button {
        // .add-to-cart-form__button
        max-width: 300px;
    }

    &__button-container {
        // .add-to-cart-form__button-container
        width: 100%;
        justify-content: start;
    }
}
