// Apertura regular
@font-face {
    font-family: map-get($font-families, primary);
    src: url('#{$fonts-root-path}Apertura/Apertura-Regular.woff2') format("woff2");
    font-style: normal;
    font-weight: map-get($font-weights, normal);
    font-stretch: normal;
    font-display: swap;
}

// Apertura regular italic
@font-face {
    font-family: map-get($font-families, primary);
    src: url('#{$fonts-root-path}Apertura/Apertura-Regular-Italic.woff2') format("woff2");
    font-style: italic;
    font-weight: map-get($font-weights, normal);
    font-stretch: normal;
    font-display: swap;
}

// Apertura bold
@font-face {
    font-family: map-get($font-families, primary);
    src: url('#{$fonts-root-path}Apertura/Apertura-Bold.woff2') format("woff2");
    font-style: normal;
    font-weight: map-get($font-weights, bold);
    font-stretch: normal;
    font-display: swap;
}

// Apertura bold italic
@font-face {
    font-family: map-get($font-families, primary);
    src: url('#{$fonts-root-path}Apertura/Apertura-Bold-Italic.woff2') format("woff2");
    font-style: italic;
    font-weight: map-get($font-weights, bold);
    font-stretch: normal;
    font-display: swap;
}

// linotype-didot regular
@font-face {
    font-family: map-get($font-families, secondary);
    src: url('#{$fonts-root-path}Linotype-didot/Linotype-didot-Regular.woff2') format("woff2");
    font-style: normal;
    font-weight: map-get($font-weights, normal);
    font-stretch: normal;
    font-display: swap;
}

// linotype-didot regular italic
@font-face {
    font-family: map-get($font-families, secondary);
    src: url('#{$fonts-root-path}Linotype-didot/Linotype-didot-Regular-Italic.woff2') format("woff2");
    font-style: italic;
    font-weight: map-get($font-weights, normal);
    font-stretch: normal;
    font-display: swap;
}

// linotype-didot bold
@font-face {
    font-family: map-get($font-families, secondary);
    src: url('#{$fonts-root-path}Linotype-didot/Linotype-didot-Bold.woff2') format("woff2");
    font-style: normal;
    font-weight: map-get($font-weights, bold);
    font-stretch: normal;
    font-display: swap;
}

// linotype-didot bold italic
@font-face {
    font-family: map-get($font-families, secondary);
    src: url('#{$fonts-root-path}Linotype-didot/Linotype-didot-Bold-Italic.woff2') format("woff2");
    font-style: italic;
    font-weight: map-get($font-weights, bold);
    font-stretch: normal;
    font-display: swap;
}

// Seasons
@font-face {
    font-family: 'SeasonsScript-Regular';
    src: url('#{$fonts-root-path}Seasons/seasons-script-regular.woff2') format('woff2');
    font-weight: map-get($font-weights, normal);
    font-style: normal;
    font-display: swap;
}

// Iconfont
@font-face {
    font-family: 'iconfont';
    src: url('#{$fonts-root-path}icons/iconfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
