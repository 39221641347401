@use "sass:math";
@use "sass:list";

@import "./functions";

@mixin clearfix($pseudo:after) {
    &::#{$pseudo} {
        display: block;
        clear: both;
        content: "";
    }
}

@mixin image-placeholder($width, $height) {
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: percentage(math.div($height, $width));
    position: relative;

    > img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

/*
Usage

.block {
    @include arrow(right,red,5px,auto,auto,0,0,auto,-5px);

    width: 50px;
    height: 50px;
    position: relative;
}
*/

/*
Usage
    @include ratio-holder(364/300)
    Height/Width
*/

@mixin ratio-holder($value: 1, $pseudo: before) {
    @include clearfix;

    &::#{$pseudo} {
        content: "";
        float: left;
        padding-bottom: percentage($value);
    }
}

@mixin arrow($direction, $color:#000, $size: 0, $top: auto, $right: auto, $bottom: auto, $left: auto, $margin_top: auto, $margin_bottom: auto) {
    position: relative;

    @if $direction==self {
        display: inline-block;
        height: 0;
        vertical-align: middle;
        width: 0;
    }

    @if $direction==right {
        &::before {
            content: "";
            position: absolute;
            top: $top;
            left: $left;
            right: $right;
            bottom: $bottom;
            border: $size solid transparent;
            border-left: $size solid $color;
            margin-top: $margin_top;
            margin-bottom: $margin_bottom;
        }
    }

    @if $direction==left {
        &::before {
            content: "";
            position: absolute;
            top: $top;
            left: $left;
            right: $right;
            bottom: $bottom;
            border: $size solid transparent;
            border-right: $size solid $color;
            margin-top: $margin_top;
            margin-bottom: $margin_bottom;
        }
    }

    @if $direction==top {
        &::before {
            content: "";
            position: absolute;
            top: $top;
            left: $left;
            right: $right;
            bottom: $bottom;
            border: $size solid transparent;
            border-bottom: $size solid $color;
            margin-top: $margin_top;
            margin-bottom: $margin_bottom;
        }
    }

    @if $direction==bottom {
        &::before {
            content: "";
            position: absolute;
            top: $top;
            left: $left;
            right: $right;
            bottom: $bottom;
            border: $size solid transparent;
            border-top: $size solid $color;
            margin-top: $margin_top;
            margin-bottom: $margin_bottom;
        }
    }
}

@mixin closeButton ($size, $thickness, $color, $colorHover) {
    display: inline-block;
    width: $size;
    height: $size;

    &::before,
    &::after {
        content: '';
        display: block;
        width: 140%;
        height: $thickness;
        background: $color;
    }

    &::before {
        transform: rotate(45deg);
        transform-origin: 0 0;
        margin-top: -$thickness*0.25;
        margin-left: $thickness*0.5;
    }

    &::after {
        transform: rotate(-45deg);
        transform-origin: 100% 0;
        margin-left: -41%;
        margin-top: -$thickness;
    }

    @if $colorHover {
        &:hover {
            cursor: pointer;

            &::before,
            &::after {
                background: $colorHover;
            }
        }
    }
}

/* Mixin to cut off the rest of one-line text with dots */

@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Custom Scrollbars

@mixin custom-scrollbar($bg-color: #eee, $thumb-color: #aaa, $scrollbar-width: 4px, $scrollbar-height: 100px, $thumb-border-radius: 0, $track-border-radius: 0) {
    @include chrome-scrollbar($bg-color, $thumb-color, $scrollbar-width, $scrollbar-height, $thumb-border-radius, $track-border-radius);
    @include ie-scrollbar($bg-color, $thumb-color, $scrollbar-width, $scrollbar-height);
    @include mozilla-scrollbar($bg-color, $thumb-color);
}

@mixin chrome-scrollbar($bg-color, $thumb-color, $scrollbar-width: 6px, $scrollbar-height: 0.375rem, $thumb-border-radius: 6.25rem, $track-border-radius: 0) {
    &::-webkit-scrollbar {
        width: $scrollbar-width;
        height: $scrollbar-height;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border-radius: $thumb-border-radius;
        margin: 0.625rem;
    }

    &::-webkit-scrollbar-track {
        background: $bg-color;
        border-radius: $track-border-radius;
    }
}

@mixin ie-scrollbar($bg-color, $thumb-color, $scrollbar-width: 6px, $scrollbar-height: 0.375rem) {
    @if $bg-color == transparent {
        $bg-color: $white;
    }

    scrollbar-base-color: $thumb-color;
    scrollbar-face-color: $thumb-color;
    scrollbar-3dlight-color: $bg-color; // stylelint-disable-line
    scrollbar-arrow-color: $bg-color;
    scrollbar-highlight-color: $bg-color;
    scrollbar-shadow-color: $bg-color;
    scrollbar-dark-shadow-color: $bg-color;
    scrollbar-track-color: $bg-color;
}

@mixin mozilla-scrollbar($bg-color, $thumb-color) {
    scrollbar-color: $thumb-color $bg-color;// stylelint-disable-line
    scrollbar-track-color: $bg-color;
    scrollbar-width: thin; // stylelint-disable-line
}

@mixin ie-scrollbar($bg-color, $thumb-color, $scrollbar-width: 6px, $scrollbar-height: 0.375rem) {
    @if $bg-color == transparent {
        $bg-color: $white;
    }

    scrollbar-base-color: $thumb-color;
    scrollbar-face-color: $thumb-color;
    scrollbar-3dlight-color: $bg-color; // stylelint-disable-line
    scrollbar-arrow-color: $bg-color;
    scrollbar-highlight-color: $bg-color;
    scrollbar-shadow-color: $bg-color;
    scrollbar-dark-shadow-color: $bg-color;
    scrollbar-track-color: $bg-color;
}

@mixin button-reset {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

@mixin make-btn-active-state($bg-color-hover, $border-color-hover, $color-hover) {
    background-color: $bg-color-hover;
    border-color: $border-color-hover;
    color: $color-hover;
    box-shadow: none;
}

@mixin make-btn($bg-color, $border-color, $color, $bg-color-hover, $border-color-hover, $color-hover, $with-active) {
    background-color: $bg-color;
    border-color: $border-color;
    color: $color;

    @if $with-active {
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
            @include make-btn-active-state($bg-color-hover, $border-color-hover, $color-hover);
        }
    } @else {
        &:not(:disabled):not(.disabled):not(.active):hover {
            @include make-btn-active-state($bg-color-hover, $border-color-hover, $color-hover);
        }
    }
}

@mixin underline() {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

@mixin circle($diameter, $color) { // Source:  https://gist.github.com/kogakure/4769904
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    width: $diameter;
    height: $diameter;
    background: $color;
    -moz-border-radius: $diameter*0.5;
    border-radius: $diameter*0.5;
}

@mixin u-section-header {
    font: normal $fw-black list.slash($fs-large, $lh-small) $font-family-base;
    letter-spacing: $ls-xl;
    text-transform: uppercase;
    color: $headings-color-alt;

    @include media-breakpoint-up(md) {
        font-size: $fs-xl;
        letter-spacing: $ls-xxxl;
    }
}

@mixin fs-fluid-d($font-size) {
    @include media-breakpoint-up(ls) {
        font-size: calc(#{$font-size}/1440 * 100vw);
    }
}
@mixin fs-fluid-m($font-size) {
    @include media-breakpoint-down(xs) {
        font-size: calc(#{$font-size}/430 * 100vw );
    }
}
@mixin fs-fluid-t($font-size) {
    @include media-breakpoint-between(sm, md) {
        font-size: calc(#{$font-size}/768 * 100vw );
    }
}
