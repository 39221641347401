// ===================================
// Spacing (Units used to space elements within the website.)
//

$spacer-base:       5px !default;

$spacer-05:         $spacer-base * 1 !default; // 5px
$spacer-10:         $spacer-base * 2 !default; // 10px
$spacer-15:         $spacer-base * 3 !default; // 15px
$spacer-20:         $spacer-base * 4 !default; // 20px
$spacer-25:         $spacer-base * 5 !default; // 25px
$spacer-30:         $spacer-base * 6 !default; // 30px
$spacer-35:         $spacer-base * 7 !default; // 35px
$spacer-40:         $spacer-base * 8 !default; // 40px
$spacer-45:         $spacer-base * 9 !default; // 45px
$spacer-50:         $spacer-base * 10 !default; // 50px
$spacer-55:         $spacer-base * 11 !default; // 55px
$spacer-60:         $spacer-base * 12 !default; // 60px
$spacer-65:         $spacer-base * 13 !default; // 65px
$spacer-70:         $spacer-base * 14 !default; // 70px

// Re-write bootstrap spacer value here
$spacing-xs:        $spacer-base * .25 !default;
$spacing-s:         $spacer-base * .5 !default;
$spacing-m:         $spacer-base * 1.5 !default;
$spacing-l:         $spacer-base * 3 !default;
$spacer-standard:         24px !default; // 24px
