.list-slider {
    position: relative;
    min-height: 44px;

    &__item {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        color: $color-black;
        transition: opacity 1s;

        a {
            color: inherit;
        }

        &.is-active {
            opacity: 1;
        }
    }
}
