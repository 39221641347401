.a-plus {
    &__wrapper {
        margin-top: $spacer-30;
        padding-top: $spacer-30;
        border-top: 1px solid $color-gray;

        .headline-feature {
            text-transform: none;
            font-weight: 700;
        }

        @include media-breakpoint-up(md) {
            margin-top: $spacer-50;
        }
    }

    &__column {
        margin-bottom: $spacer-30;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__one-card {
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        &--width100 {
            position: relative;
        }
    }

    &__photo {
        margin-bottom: $spacer-20;
    }

    &__photo-src {
        width: 100%;
    }

    &__text {
        @include media-breakpoint-up(ls) {
            font-size: $fs-medium;
        }
    }

    &__title {
        margin-bottom: $spacer-15;
        letter-spacing: $ls-medium;

        .h3 {
            @include media-breakpoint-up(ls) {
                font-size: 28px;
            }
        }
    }

    &__smaller-mobile-gutter {
        &-right{
            padding-right: $spacer-05;
        }

        &-left {
            padding-left: $spacer-05;
        }

        @include media-breakpoint-up(ls) {
            &-right{
                padding-right: $spacer-20;
            }

            &-left {
                padding-left: $spacer-20;
            }
        }
    }

    &__iframe {
        iframe {
            width: 100%;
        }
    }

    &__overlap-card {
        &[data-youtube-id] {
            cursor: pointer;
        }

        .a-plus {
            &__photo {
                margin-bottom: 0;
            }

            &__overlap-wrapper {
                position: relative;

                @include icon($fi--play, $pseudo: after) {
                    color: $color-white;
                    font-size: $icon-size-xxxl;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: $icon-size-xxxl;
                    height: $icon-size-xxxl;
                    text-shadow: 0 0 2px $color-black;
                }

                @include media-breakpoint-up(md) {
                    &::after {
                        left: $spacer-30;
                        bottom: $spacer-20;
                        right: auto;
                        top: auto;
                    }
                }
            }

            &__first-content {
                position: absolute;
                top: $spacer-20;
                left: $spacer-15;
                text-align: left;

                @include media-breakpoint-up(md) {
                    top: $spacer-35;
                    left: $spacer-30;
                }
            }

            &__second-content {
                position: absolute;
                bottom: $spacer-20;
                left: 0;
                width: 100%;
                text-align: center;

                @include media-breakpoint-up(md) {
                    width: 330px;
                    right: $spacer-20;
                    left: auto;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            &__second-title {
                letter-spacing: $ls-xxl;
                font-size: $fs-xxl;
                margin-bottom: $spacer-10;
                font-family: $font-family-secondary;
                line-height: $lh-medium;

                @include media-breakpoint-up(md) {
                    font-size: $fs-xxxl;
                }
            }

            &__second-text {
                letter-spacing: $ls-medium;
                font-size: $fs-large;
            }

            @include media-breakpoint-down(ls) {
                &__photo {
                    overflow: hidden;
                }

                &__photo-root {
                    display: inline-block;
                    position: relative;
                    right: -50%;
                }

                &__photo-src {
                    position: relative;
                    left: -50%;
                    min-width: 500px;
                }
            }
        }

        .color-white {
            color: $color-white;
        }

        .color-black {
            color: $color-black;
        }

        .color-desktop-white {
            @include media-breakpoint-up(sm) {
                color: $color-white;
            }
        }

        .color-desktop-black {
            @include media-breakpoint-up(sm) {
                color: $color-black;
            }
        }

        .color-mobile-white {
            @include media-breakpoint-down(sm) {
                color: $color-white;
            }
        }

        .color-mobile-black {
            @include media-breakpoint-down(sm) {
                color: $color-black;
            }
        }
    }
}
