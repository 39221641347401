.font-family-barlow {
    font-family: 'Barlow Condensed' !important;
}

.font-family-playfair {
    font-family: 'Playfair Display' !important;
}

.font-family-inter {
    font-family: 'Inter' !important;
}

.font-family-cardo {
    font-family: 'Cardo' !important;
}

.font-family-seasons {
    font-family: 'SeasonsScript-Regular', Helvetica, Arial, Sans-Serif !important;
    text-transform: none;
}

.font-family-public-sans {
    font-family: 'Public Sans' !important;
}

.color-gg-green {
    color: #47654f;
}

.five-item {
    @include media-breakpoint-up(ls) {
        padding: 12px;
    }
}

.gg-hero-1:first-of-type .photo-tile-image {
    @include media-breakpoint-up(ls) {
        max-height: 850px;
    }
}

.gift-guide-content {
    p,
    a {
        font-family: 'Inter';
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'SeasonsScript-Regular';
        text-transform: none !important;
    }

    .b-text-and-btn.hero-1 {
        top: 0%;
        right: 50%;
        transform: translateX(50%);
    }

    .mobile-side-scroll.hero-1 {
        transform: translate(50%, 50%);
        right: 50%;
        bottom: 41%;

        @include media-breakpoint-up(sm) {
            padding: 0 6%;
        }
    }

    .gg-sub-05 {
        p {
            margin: 0 auto;
        }
    }
}
