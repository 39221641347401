.promo-banner-global{
  margin: auto;
  .promo-banner-global-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    column-gap: 8px;
    overflow-x:auto;
    padding: 0 16px;
  }
  .promo-banner{
    width: 100%;
    // background-color: #3D7C94;
    // color: white;
    text-align: center;
    line-height: 1.5;
    font-size: 14px;
    padding: 12px;
    font-weight: 400;
    min-width: 335px;
  }

  .promo-banner a{
    text-decoration: underline;
    text-underline-offset: 4px;
    display: block;
  }
  .promo-banner a:hover{
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  .slick-arrow-promo {
    font-size: 25px;
  }
}
@include media-breakpoint-up(ls) {
  .promo-banner-global-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    column-gap: 16px;
    overflow-x:auto;
    padding: 0 16px;
  
    .promo-banner{
      width: 100%;
      // background-color: #3D7C94;
      // color: white;
      text-align: center;
      line-height: 1.5;
      font-size: 14px;
      padding: 7px 0;
      min-width: 400px;
    }
  }
}