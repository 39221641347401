/* stylelint-disable */
/*md

## Forms

### Overview

```html_example

<form>
    <div class="form-group">
        <input type="text" class="form-control" id="exampleInputText1" aria-describedby="textHelp" placeholder="Text">
        <span id="textHelp" class="form-text-info">Text input help.</span>
        <span class="invalid-feedback">Error message</span>
    </div>
    <div class="form-group">
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email">
        <span id="emailHelp" class="form-text-info">We'll never share your email with anyone else.</span>
        <span class="invalid-feedback">Error message</span>
    </div>
    <div class="form-group">
        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
        <span class="invalid-feedback">Error message</span>
    </div>
    <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck1">
        <label class="form-check-label" for="exampleCheck1">Check me out</label>
        <span class="invalid-feedback">Error message</span>
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
</form>

````

### Accepted

```html_example

<form>
    <div class="form-group is-accepted">
        <input type="text" class="form-control" id="exampleInputText1" aria-describedby="textHelp" placeholder="Text" value="Example text">
        <span id="textHelp" class="form-text-info">Text input help.</span>
        <span class="invalid-feedback">Error message</span>
    </div>
    <div class="form-group is-accepted">
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value="Example text">
        <span id="emailHelp" class="form-text-info">We'll never share your email with anyone else.</span>
        <span class="invalid-feedback">Error message</span>
    </div>
    <div class="form-group is-accepted">
        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" value="Example text">
        <span class="invalid-feedback">Error message</span>
    </div>
    <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck2">
        <label class="form-check-label" for="exampleCheck2">Check me out</label>
        <span class="invalid-feedback">Error message</span>
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
</form>

````

### Form is invalid

```html_example

<form>
    <div class="form-group is-invalid">
        <input type="text" class="form-control" id="exampleInputText2" aria-describedby="textHelp2" placeholder="Text" value="Example text">
        <span id="textHelp2" class="form-text-info">Text input help.</span>
        <span class="invalid-feedback">Error message</span>
    </div>
    <div class="form-group is-invalid">
        <input type="email" class="form-control" id="exampleInputEmail2" placeholder="Email" value="Example text">
        <span class="invalid-feedback">Error message</span>
    </div>
    <div class="form-group is-invalid">
        <input type="password" class="form-control" id="exampleInputPassword2" placeholder="Password" value="Example text">
        <span class="invalid-feedback">Error message</span>
    </div>
    <div class="form-group form-check is-invalid">
        <input type="checkbox" class="form-check-input" id="exampleCheck3">
        <label class="form-check-label" for="exampleCheck3">Check me out</label>
        <span class="invalid-feedback">Error message</span>
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
</form>

````

### Radios

```html_example

<div class="form-check">
    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios10" value="option1" checked>
    <label class="form-check-label" for="exampleRadios10">
        Default radio
    </label>
</div>
<div class="form-check">
    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios20" value="option2">
    <label class="form-check-label" for="exampleRadios20">
        Second default radio
    </label>
</div>
<div class="form-check">
    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios30" value="option3" disabled>
    <label class="form-check-label" for="exampleRadios30">
        Disabled radio
    </label>
</div>

````

### Checkboxs

```html_example
<div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
    <label class="form-check-label" for="defaultCheck1">
        Default checkbox
    </label>
</div>
<div class="form-check">
    <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" disabled>
    <label class="form-check-label" for="defaultCheck2">
        Disabled checkbox
    </label>
</div>

````

### Select

```html_example

<div class="form-group">
    <select class="form-control">
        <option value="" class="placeholder" disabled selected hidden>Select...</option>
        <option>State</option>
        <option>State2</option>
        <option>State3</option>
    </select>
    <span class="invalid-feedback">Error message</span>
</div>

<div class="form-group is-accepted">
    <select class="form-control">
        <option value="" class="placeholder" disabled selected hidden>Select...</option>
        <option>State</option>
        <option>State2</option>
        <option>State3</option>
    </select>
    <span class="invalid-feedback">Error message</span>
</div>

<div class="form-group is-invalid">
    <select class="form-control" aria-describedby="textHelp3">
        <option value="" class="placeholder" disabled selected hidden>Select...</option>
        <option>State</option>
        <option>State2</option>
        <option>State3</option>
    </select>
    <span id="textHelp3" class="form-text-info">Select input help.</span>
    <span class="invalid-feedback">Error message</span>
</div>

````

## Form example

### Shipping

```html_example
<form>
    <div class="row form-title align-items-end">
        <div class="col-md-4">
            <div class="h4 form-title__name mb-md-0">Shipping</div>
        </div>
        <div class="col-md-8 text-md-right">
            <a href="#" class="form-title__link mb-md-0">Select from Saved Addresses</a>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Full Name">
                <span class="invalid-feedback">Error message</span>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Street Address">
                <span class="invalid-feedback">Error message</span>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Apartment, Suite, Building (optional)">
                <span class="invalid-feedback">Error message</span>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="City">
                <span class="invalid-feedback">Error message</span>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <select class="form-control">
                    <option value="" class="placeholder" disabled selected hidden>Select...</option>
                    <option>State</option>
                    <option>State2</option>
                    <option>State3</option>
                </select>
                <span class="invalid-feedback">Error message</span>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Zip Code">
                <span class="invalid-feedback">Error message</span>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Phone (in case of delivery questions)">
                <span class="invalid-feedback">Error message</span>
            </div>
        </div>
    </div>

    <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="save">
        <label class="form-check-label" for="save">Save to Address Book</label>
        <span class="invalid-feedback">Error message</span>
    </div>

    <button type="submit" class="btn btn-primary">Submit</button>
</form>
````

*/
/* stylelint-enable */

.form-group select.form-control {
    border-radius: 0;
}
