.attribute {
    &-default {
        // .attribute-default
        margin-bottom: $spacer-20;
    }

    &-color {
        // .attribute-color
        margin-bottom: $spacer-10;
    }

    &-size,
    &-width {
        // .attribute-size, .attribute-width
        margin-bottom: $spacer-10;
    }

    &__btn {
        // .attribute__btn
        margin-bottom: $spacer-10;
        margin-right: $spacer-05;
    }

    &__label {
        // .attribute__label
        font-weight: fw(medium);
        display: inline-block;
        margin-bottom: $spacer-10;
    }

    &__btn-swatch {
        // .attribute__btn-swatch
        border: 1px solid $color-transparent;
        padding: 1px;
        overflow: hidden;
        border-radius: 12px;
        transition: all, 0.2s;
        background-color: $color-white;

        &[disabled] {
            opacity: 0.4;
            filter: grayscale(1);
        }

        &:not([disabled]):hover,
        &.selected {
            border-color: $color-gray;
            outline: 1px solid $color-gray;
        }
    }

    &__swatch {
        // .attribute__swatch
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.attribute-planet-a {
    .attribute-default {
        // .attribute-default
        margin-bottom: $spacer-20;
    }

    .attribute-color {
        // .attribute-color
        margin-bottom: $spacer-10;
    }

    .attribute-size,
    .attribute-width {
        // .attribute-size, .attribute-width
        margin-bottom: $spacer-10;
    }

    .attribute__btn {
        // .attribute__btn
        margin-bottom: $spacer-10;
        margin-right: $spacer-05;
    }

    .attribute__label {
        // .attribute__label
        font-weight: fw(medium);
        display: inline-block;
        margin-bottom: $spacer-10;
    }

    .attribute__btn-swatch {
        // .attribute__btn-swatch
        border: 1px solid $color-transparent;
        padding: 1px;
        overflow: hidden;
        border-radius: 50%;
        transition: all, 0.2s;
        background-color: $color-white;

        &[disabled] {
            opacity: 0.4;
            filter: grayscale(1);
        }

        &:not([disabled]):hover,
        &.selected {
            border-color: black;
            outline: 1px solid black;
        }
    }

    .attribute__swatch {
        // .attribute__swatch
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}
