// ===================================
// Carouseles
// ===================================
//
// This file gathers carouseles global styles.
//

// Slick styles

.slick-loading .slick-list {
    background: $color-primary-alt !important;
}

[data-slick] {
    visibility: hidden;

    .slick-track {
        display: flex;
    }

    &.slick-initialized {
        visibility: visible;
    }

    .slick-slide {
        height: auto;

        img {
            // center image if smaller then container. width:100% might in some cases stretch it unproportionally.
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 30px;
    }

    .slick-dots {
        bottom: -30px;
        li {
            height: auto;
            width: auto;
            margin: 0;
            padding: 7px;

            &.slick-active {
                button {
                    border: 1px solid $color-gray;
                    background-color: $color-gray;
                }
            }

            button {
                width: 15px;
                height: 15px;
                padding: 0;
                border: 1px solid $color-dove-gray;
                border-radius: 100%;
                background-color: $color-white;

                &::before {
                    content: none;
                }
            }
        }
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 1;
        border-radius: 100%;
        transform: translateY(-50%);
        font-size: 39px;
        background-color: rgba($color-white, 0.5);
        cursor: pointer;

        &::before {
            position: relative;
        }

        &.slick-arrow-left {
            left: $spacer-05;
        }

        &.slick-arrow-right {
            right: $spacer-05;
        }
    }
}

// Custom carousel styles
.carousel {
    &__title {
        &-wrapper {
            // .carousel__titlewrapper
            text-align: center;
        }

        &-small {
            // .carousel__title-small
            font-size: $fs-medium;
            margin-bottom: $spacer-30;
            font-weight: fw(medium);
        }

        &-img {
            // .carousel__title-img
            max-width: 350px;
        }
    }

    // .carousel__slider (for banner sliders)
    &__banner {
        // set the same height for sliders
        .slick-track {
            display: flex !important;
        }

        .slick-initialized .slick-slide {
            display: grid;
        }

        .slick-arrow {
            display: none !important;
        }
    }

    &__contentcarousel {
        .slick-slide {
            margin: 0 $spacer-20;
        }
    }

    &__contentcarousel,
    &__instagramcarousel {
        .slick-arrow {
            top: -20px;

            @include media-breakpoint-up(ls) {
                top: -39px;
            }

            &.slick-arrow-left {
                left: 0;
            }

            &.slick-arrow-right {
                right: 0;
            }
        }

        .carousel__title-wrapper {
            margin-bottom: 39px;
        }
    }

    &__instagramcarousel {
        .slick-slide {
            margin: 0 $spacer-05;
        }
    }

    &__tricarousel {
        // .carousel__title-wrapper,
        // .slick-slide img{
        //     padding: 0 4%;
        // }

        .carousel__title-wrapper {
            padding: 0 50px;
            
            @include media-breakpoint-down(xs) {
                margin: 6% 0;
                text-align: center;

                h2 {
                    font-size: calc(24/375 * 100vw );
                    margin-bottom: 3%;
                    color: #4d7e85;
                }

                p {
                    font-size: calc(14/375 * 100vw );
                    color: #4d7e85;
                }
            }
            @include media-breakpoint-up(sm) {
                margin: 2% 0;

                .carousel__title {
                    padding: 0 3%;
                    text-align: left;
                }

                h2{
                    font-size: calc(50/1440 * 100vw );
                    margin: 0;
                    color: #4d7e85;
                }

                p{
                    font-size: calc(19/1440 * 100vw );
                    color: #4d7e85;
                }
            }
        }

        .slick-track {
            @include media-breakpoint-down(xs) {
                gap: 1%;
            }
            @include media-breakpoint-up(sm) {
                gap: 3%;
            }
        }
    }

    &__categorycarousel {
        .slick-track {
            @include media-breakpoint-down(xs) {
                gap: 8px;
                .carousel__item  {
                    max-width: 80px;

                    .b-image-and-text__link {
                        font-size: 10px;
                        letter-spacing: 0px;
                    }
                }

            }
            @include media-breakpoint-up(sm) {
                .carousel__item  {
                    padding: 0 12px;
                }
            }
        }
        .slick-arrow {
            background-color: transparent;
        }

        [data-slick] .slick-arrow.slick-arrow-left {
            left: -3%;
        }

        [data-slick] .slick-arrow.slick-arrow-right {
            right: -3%;
        }
    }

    &__productcarousel, &__productcarousel3 { 

        h1,h2,h3,h4,h5,h6 {
            text-transform: none;
        }

        h2 {
            color: #242424;
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 32px */
            letter-spacing: -0.96px;
        }

        p {
            color: #262626;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 1.3px;
            line-height: 150%; /* 24px */
        }

        .slick-slider {
            margin: $spacer-standard 0; 
        }

        .slick-track{
            gap: 1rem;
        }

        .carousel__title-wrapper {
            padding: 0 1rem;
        }

        @include media-breakpoint-up(ls) {
            padding: 0 3%;

            h2 {
                color: #242424;
                font-family: "Playfair Display";
                font-size: 3rem;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 48px */
                letter-spacing: -1.44px;
            }


            .slick-track{
                gap: 1.5rem;
            }

            .carousel__title-wrapper {
                padding: 0 9.7%;
            }

            [data-slick] .slick-arrow {
                background-color: transparent;
            }
        }


        [data-slick] .slick-arrow.slick-arrow-left {
            left: -3%;
        }

        [data-slick] .slick-arrow.slick-arrow-right {
            right: -3%;
        }
    }

    &__productcarousel4 { 
        h2 {
            font-family: 'Inter';
            text-align: left;
            font-weight: 700;
            letter-spacing: -1.8px;
            font-size: 36px;
            margin-left: 16.6px;
            @include media-breakpoint-up(sm) {
                font-size: 48px;
            }
        }
    }

    &__productcarousel3 {
        .carousel__title-wrapper {
            padding: 0;

            h2 {
                font-family: 'Inter';
                text-align: left;
                font-weight: 700;
                letter-spacing: -1.8px;
                font-size: 36px;
                margin-left: 16.6px;
                @include media-breakpoint-up(sm) {
                    font-size: 48px;
                }
                @include media-breakpoint-up(ls) {
                    font-size: 80px;
                }
            }
        }
    }
}

.styles-we-love{
    h1,h2,h3,h4,h5,h6 {
        text-transform: none;
    }

    h2 {
        font-size: clamp(20px,7vw,44px);;
    }
}