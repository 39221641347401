.recommendations {
    width: 100%;
    margin-top: $spacer-60;

    @include media-breakpoint-up(ls) {
        margin-top: 100px;
    }

    &--minicart {
        margin-top: $spacer-40;

        @include media-breakpoint-up(ls) {
            margin-top: $spacer-40;
        }
    }

    [data-slick] {
        .slick-arrow.slick-arrow-left {
            left: -$spacer-20;
        }

        .slick-arrow.slick-arrow-right {
            right: -$spacer-20;
        }

        .slick-track {
            display: flex;
        }

        .slick-slide {
            float: none;
        }
    }

    .product {
        height: 100%;
        padding: 0 $spacer-10;
    }
}

.recommendations-cart-page {
    // TODO FE: @Evhen, please consider refactor this becasue we need to add that top line i.e. on a cart page ONLY when slot is visible (configured)
    // so we have part of this markup
    padding-top: $spacer-20;
    border-top: 1px solid $color-gray-lighter;

    .quickview {
        display: none!important;
    }
}
