.form-group {
    &.is-accepted {
        .form-control {
            background-color: $color-gray-light;
            color: $color-black
        }
    }

    &.is-invalid {
        .invalid-feedback {
            display: block;
        }

        .form-control {
            color: $color-red;
        }
    }

    .form-control {
        &:focus::placeholder {
            color: $color-transparent;
        }
    }

    select.form-control {
        padding: 13px $spacer-30 13px $spacer-15;
        position: relative;
        -moz-appearance:none;
        -webkit-appearance:none;
        appearance:none;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMCAyMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGcgaWQ9Ikljb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0wLjE4MyAwLjExNikiPg0KCTxnIGlkPSJJY29uX0lDT05fZmVhdGhlcl9jaGV2cm9uLWRvd25fU0laRV9NRURJVU1fU1RZTEVfU1RZTEUxXyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNC45NzkgNy40NjkpIj4NCgkJPGcgaWQ9IlBhdGgiPg0KCQkJPHBvbHlnb24gcG9pbnRzPSI1LDUuNyAtMC40LDAuNCAwLjQsLTAuNCA1LDQuMyA5LjYsLTAuNCAxMC4zLDAuNCAJCQkiLz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K");
        background-repeat: no-repeat;
        background-position: right $spacer-05 center;
        background-size: 20px;
        border-radius: 6px;

        option {
            background-color: $color-white;

            &:not(:first-of-type) {
                color: $color-black-text;
            }

            // TODO: placeholder color ?
        }
    }

    .form-text-info {
        display: block;
        margin-top: $spacer-05;
        font-size: $fs-xs;
        line-height: $lh-xs;
        padding-left: $spacer-15;
    }

    .invalid-feedback {
        display: none;
        color: $color-red;
        font-size: $fs-base;
        line-height: $lh-base;
        margin: $spacer-05 0 0 0;
        padding-left: $spacer-15;
    }

    &.required .form-control-label::after {
        content: "*";
        color: $color-red;
        padding-left: 3px;
    }

    .form-control-label--nowrap {
        white-space: nowrap;
    }


    &__action-buttons {
        display: flex;
        justify-content: flex-end;
        gap: $spacer-20;
        margin: $spacer-05 0;
    }
}

.form-check {
    margin-bottom: $spacer-15;

    .invalid-feedback {
        margin-top: 0;
        padding-left: 0;
    }

    .form-text-info {
        padding-left: 0;
    }

    &-input {

        &:checked,
        &:not(:checked) {
            display: none;
        }

        &:checked+.form-check-label,
        &:not(:checked)+.form-check-label {
            position: relative;
            padding: 1px 0 0 22px;
            display: inline-block;
        }

        &:disabled+.form-check-label:hover {
            cursor: not-allowed;
        }

        &:checked:not(:disabled):not(.disabled)+.form-check-label,
        &:not(:checked):not(:disabled):not(.disabled)+.form-check-label {
            cursor: pointer;
        }

        &:not(:checked):not(:disabled):not(.disabled)+.form-check-label:hover::before {
            background-color: $color-gray-light;
        }

        &:checked+.form-check-label::before {
            background-color: $color-gray-light;
        }

        &:not(:checked)+.form-check-label::after {
            opacity: 0;
        }

        &:checked+.form-check-label::after {
            opacity: 1;
        }

        &:disabled {
            +.form-check-label {
                color: $color-gray;
            }
        }
    }


    &-input[type="radio"] {

        &:checked+.form-check-label::before,
        &:not(:checked)+.form-check-label::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            border: 1px solid $color-gray;
            background: $color-transparent;
        }

        &:checked+.form-check-label::after,
        &:not(:checked)+.form-check-label::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            margin: 3px 0 0 3px;
            background-color: $color-gray;
        }
    }

    &-input[type="checkbox"] {
        &:checked+.form-check-label::before,
        &:not(:checked)+.form-check-label::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 16px;
            height: 16px;
            border: 1px solid $color-gray;
            background: $color-transparent;
        }

        &:checked+.form-check-label::after,
        &:not(:checked)+.form-check-label::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 5px;
            height: 9px;
            margin: 2px 0 0 6px;
            border: solid $color-gray;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }

    .form-check-label {
        font-size: $fs-base;
        line-height: $lh-xs;
        color: $color-black;
        margin-bottom: 0;
    }
}

.form-title {
    margin-bottom: $spacer-20;

    &__name {
        margin-bottom: $spacer-15;
    }

    &__link {
        text-decoration: underline;
        margin-bottom: $spacer-10;
        &:hover {
            text-decoration: none;
        }
    }
}

.custom-dropdown {
    &__menu {
        padding: $spacer-10 $spacer-20;
    }

    &__menu-item {
        white-space: nowrap;
        padding: $spacer-05 0;

        &.is-selected {
            color: $color-primary-alt;
        }
    }
}
