/* stylelint-disable */
@if variable-exists('globalCssFile') {
/*md

# Icons

## Icons example:

```html_example
<table>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">account:</span></td>
    <td class="p-2"><i class="fi fi--account" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">arrow-bottom:</span></td>
    <td class="p-2"><i class="fi fi--arrow-bottom" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">arrow-top:</span></td>
    <td class="p-2"><i class="fi fi--arrow-top" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">bag:</span></td>
    <td class="p-2"><i class="fi fi--bag" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">carrot-bottom:</span></td>
    <td class="p-2"><i class="fi fi--carrot-bottom" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">carrot-left:</span></td>
    <td class="p-2"><i class="fi fi--carrot-left" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">carrot-right:</span></td>
    <td class="p-2"><i class="fi fi--carrot-right" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">carrot-top:</span></td>
    <td class="p-2"><i class="fi fi--carrot-top" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">chart-x:</span></td>
    <td class="p-2"><i class="fi fi--chart-x" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">checkmark:</span></td>
    <td class="p-2"><i class="fi fi--checkmark" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">close-button:</span></td>
    <td class="p-2"><i class="fi fi--close-button" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">close-icon:</span></td>
    <td class="p-2"><i class="fi fi--close-icon" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">compare:</span></td>
    <td class="p-2"><i class="fi fi--compare" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">email:</span></td>
    <td class="p-2"><i class="fi fi--email" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">filter:</span></td>
    <td class="p-2"><i class="fi fi--filter" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">foot-1:</span></td>
    <td class="p-2"><i class="fi fi--foot-1" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">foot-2:</span></td>
    <td class="p-2"><i class="fi fi--foot-2" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">menu:</span></td>
    <td class="p-2"><i class="fi fi--menu" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">minus:</span></td>
    <td class="p-2"><i class="fi fi--minus" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">pause:</span></td>
    <td class="p-2"><i class="fi fi--pause" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">play:</span></td>
    <td class="p-2"><i class="fi fi--play" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">plus:</span></td>
    <td class="p-2"><i class="fi fi--plus" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">reviews:</span></td>
    <td class="p-2"><i class="fi fi--reviews" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">search:</span></td>
    <td class="p-2"><i class="fi fi--search" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">soc-email:</span></td>
    <td class="p-2"><i class="fi fi--soc-email" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">soc-facebook:</span></td>
    <td class="p-2"><i class="fi fi--soc-facebook" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">soc-instagram:</span></td>
    <td class="p-2"><i class="fi fi--soc-instagram" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">soc-link:</span></td>
    <td class="p-2"><i class="fi fi--soc-link" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">soc-pinterest:</span></td>
    <td class="p-2"><i class="fi fi--soc-pinterest" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">soc-twitter:</span></td>
    <td class="p-2"><i class="fi fi--soc-twitter" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">soc-youtube:</span></td>
    <td class="p-2"><i class="fi fi--soc-youtube" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">sort:</span></td>
    <td class="p-2"><i class="fi fi--sort" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">star:</span></td>
    <td class="p-2"><i class="fi fi--star" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">support:</span></td>
    <td class="p-2"><i class="fi fi--support" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">washingcar:</span></td>
    <td class="p-2"><i class="fi fi--washingcar" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">wish-list:</span></td>
    <td class="p-2"><i class="fi fi--wish-list" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">zoom-minus:</span></td>
    <td class="p-2"><i class="fi fi--zoom-minus" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

<tr>
    <td class="p-2"><span class="b-icon-example__label d-inline-block m-width-200">zoom-plus:</span></td>
    <td class="p-2"><i class="fi fi--zoom-plus" aria-hidden="true" style="font-size: 25px;"></i></td>
</tr>

</table>
```
*/
}

$icons: ();
@if not variable-exists('icons') {
    $icons: ();
}

$icons: map-merge($icons, (

  'account': ('iconfont' '\ea01'),

  'arrow-bottom': ('iconfont' '\ea02'),

  'arrow-top': ('iconfont' '\ea03'),

  'bag': ('iconfont' '\ea04'),

  'carrot-bottom': ('iconfont' '\ea05'),

  'carrot-left': ('iconfont' '\ea06'),

  'carrot-right': ('iconfont' '\ea07'),

  'carrot-top': ('iconfont' '\ea08'),

  'chart-x': ('iconfont' '\ea09'),

  'checkmark': ('iconfont' '\ea0a'),

  'close-button': ('iconfont' '\ea0b'),

  'close-icon': ('iconfont' '\ea0c'),

  'compare': ('iconfont' '\ea0d'),

  'email': ('iconfont' '\ea0e'),

  'filter': ('iconfont' '\ea0f'),

  'foot-1': ('iconfont' '\ea10'),

  'foot-2': ('iconfont' '\ea11'),

  'menu': ('iconfont' '\ea12'),

  'minus': ('iconfont' '\ea13'),

  'pause': ('iconfont' '\ea14'),

  'play': ('iconfont' '\ea15'),

  'plus': ('iconfont' '\ea16'),

  'reviews': ('iconfont' '\ea17'),

  'search': ('iconfont' '\ea18'),

  'soc-email': ('iconfont' '\ea19'),

  'soc-facebook': ('iconfont' '\ea1a'),

  'soc-instagram': ('iconfont' '\ea1b'),

  'soc-link': ('iconfont' '\ea1c'),

  'soc-pinterest': ('iconfont' '\ea1d'),

  'soc-twitter': ('iconfont' '\ea1e'),

  'soc-youtube': ('iconfont' '\ea1f'),

  'sort': ('iconfont' '\ea20'),

  'star': ('iconfont' '\ea21'),

  'support': ('iconfont' '\ea22'),

  'washingcar': ('iconfont' '\ea23'),

  'wish-list': ('iconfont' '\ea24'),

  'zoom-minus': ('iconfont' '\ea25'),

  'zoom-plus': ('iconfont' '\ea26'),

));

@if variable-exists('globalCssFile') {
    :root {
    
        --icon--account: '\ea01';
    
        --icon--arrow-bottom: '\ea02';
    
        --icon--arrow-top: '\ea03';
    
        --icon--bag: '\ea04';
    
        --icon--carrot-bottom: '\ea05';
    
        --icon--carrot-left: '\ea06';
    
        --icon--carrot-right: '\ea07';
    
        --icon--carrot-top: '\ea08';
    
        --icon--chart-x: '\ea09';
    
        --icon--checkmark: '\ea0a';
    
        --icon--close-button: '\ea0b';
    
        --icon--close-icon: '\ea0c';
    
        --icon--compare: '\ea0d';
    
        --icon--email: '\ea0e';
    
        --icon--filter: '\ea0f';
    
        --icon--foot-1: '\ea10';
    
        --icon--foot-2: '\ea11';
    
        --icon--menu: '\ea12';
    
        --icon--minus: '\ea13';
    
        --icon--pause: '\ea14';
    
        --icon--play: '\ea15';
    
        --icon--plus: '\ea16';
    
        --icon--reviews: '\ea17';
    
        --icon--search: '\ea18';
    
        --icon--soc-email: '\ea19';
    
        --icon--soc-facebook: '\ea1a';
    
        --icon--soc-instagram: '\ea1b';
    
        --icon--soc-link: '\ea1c';
    
        --icon--soc-pinterest: '\ea1d';
    
        --icon--soc-twitter: '\ea1e';
    
        --icon--soc-youtube: '\ea1f';
    
        --icon--sort: '\ea20';
    
        --icon--star: '\ea21';
    
        --icon--support: '\ea22';
    
        --icon--washingcar: '\ea23';
    
        --icon--wish-list: '\ea24';
    
        --icon--zoom-minus: '\ea25';
    
        --icon--zoom-plus: '\ea26';
    
    }
}

@mixin icon(
    $icon: auto,
    $pseudo: before,
    $font_size: inherit,
    $color: inherit,
    $rtlFlip: false
) {
    &::#{$pseudo} {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: $icon;
        display: inline-block;
        font-family: 'iconfont' !important;
        font-style: normal;
        font-variant: normal;
        font-weight: normal !important;
        line-height: 1;
        vertical-align: middle;
        text-rendering: auto;

        @if ($rtlFlip == true) {
            transform: none rtl(scaleX(-1));
        }

        @content;
    }
}

@mixin iconfonticons($name: null) {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: 'iconfont' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal !important;
    line-height: 1;
    vertical-align: middle;
    text-rendering: auto;

    @if ($name != null) {
        $icon: map-get($icons, $name);

        &::before {
            content: #{'"' + nth($icon, 2) + '"'};
        }
    }
}

@function get-icon-code($name) {
    $icon: map-get($icons, $name);

    @return #{'"' + nth($icon, 2) + '"'};
}


$fi--account: "\ea01";

$fi--arrow-bottom: "\ea02";

$fi--arrow-top: "\ea03";

$fi--bag: "\ea04";

$fi--carrot-bottom: "\ea05";

$fi--carrot-left: "\ea06";

$fi--carrot-right: "\ea07";

$fi--carrot-top: "\ea08";

$fi--chart-x: "\ea09";

$fi--checkmark: "\ea0a";

$fi--close-button: "\ea0b";

$fi--close-icon: "\ea0c";

$fi--compare: "\ea0d";

$fi--email: "\ea0e";

$fi--filter: "\ea0f";

$fi--foot-1: "\ea10";

$fi--foot-2: "\ea11";

$fi--menu: "\ea12";

$fi--minus: "\ea13";

$fi--pause: "\ea14";

$fi--play: "\ea15";

$fi--plus: "\ea16";

$fi--reviews: "\ea17";

$fi--search: "\ea18";

$fi--soc-email: "\ea19";

$fi--soc-facebook: "\ea1a";

$fi--soc-instagram: "\ea1b";

$fi--soc-link: "\ea1c";

$fi--soc-pinterest: "\ea1d";

$fi--soc-twitter: "\ea1e";

$fi--soc-youtube: "\ea1f";

$fi--sort: "\ea20";

$fi--star: "\ea21";

$fi--support: "\ea22";

$fi--washingcar: "\ea23";

$fi--wish-list: "\ea24";

$fi--zoom-minus: "\ea25";

$fi--zoom-plus: "\ea26";


// Usage:
// <i class="fi fi--account" aria-hidden="true"></i>
