// ===================================
// Modals
//
$modal-inner-padding:               $spacer-20 !default;

$modal-dialog-margin:               0 !default;

$modal-content-border-color:        $color-gray !default;
$modal-content-border-width:        1px !default;
$modal-content-border-radius:       0 !default;
$modal-content-box-shadow-xs:       none !default;
$modal-content-box-shadow-sm-up:    none !default;

$modal-backdrop-bg:                 $color-mine-shaft !default;
$modal-backdrop-opacity:            0.5 !default;
$modal-header-padding-y:            $spacer-20 !default;
$modal-header-padding-x:            $spacer-20 !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-header-border-color:         $color-geyser !default;

$modal-max-width:                   1000px;
