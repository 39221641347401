// ------------------------------------
// COMMON STYLES
// ------------------------------------

*:focus {
    outline: none;
}

*:focus-visible {
    /* custom focus styles */
    box-shadow: 0 0 2px 2px #51a7e87c;
  }

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-style: normal;
}

::-moz-placeholder { /* Firefox 19+ */
    font-style: normal;
}

:-ms-input-placeholder { /* IE 10+ */
    font-style: normal;
}

body,
input,
textarea,
button,
select {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Normalize

a,
button {
    color: currentColor;
}

input,
select {
    background-color: $color-white;
    color: currentColor;
    border-radius: 0;
    -webkit-appearance: none;
}

// Global styles

html {
    font-size: $base-html-font-size;
    height: 100%;
}

body {
    min-height: 100%;
    width: 100%; // fix body-scroll-lock on iOS
}

img {
    max-width: 100%;
    height: auto;

    &.js-lazy {
        max-height: 1px;
    }

    &.js-lazy-loaded {
        max-height: none;
    }
}

button:focus {
    outline: none;
}

.veil {
    z-index: $z-veil;
    position: fixed;

    body > & {
        position: fixed; // proper fullscreen veil
    }

    .underlay {
        opacity: 0.15;
    }
}

main.main {
    padding-bottom: $spacer-30;

    @include media-breakpoint-up(ls) {
        padding-bottom: $spacer-60;
    }
}

.unselectable {
    cursor: not-allowed;
}

b, strong {
    font-weight: $fw-bold;
}

// Legacy part of COMMON - feel free to refactor and edit when needed
.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
}

input[placeholder] {
    text-overflow: ellipsis;
}

.hide-order-discount {
    display: none;
}

.hide-shipping-discount {
    display: none;
}

.order-discount {
    color: $success;
}

.shipping-discount {
    color: $success;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-hero {
    background-image: url('../images/storelocator.jpg');
    margin-bottom: 0.625em;
}

.error-message {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.error-unassigned-category {
    color: $red;
}

.skip {
    position: absolute;
    left: 0;
    top: -#{$spacer-50 * 3};
    overflow: hidden;
    padding: 1em 1.5em;
    background: $white;
    transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 10000000;
    background: $white;
    transition: all 0.2s ease-in-out;
}

// END OF Legacy part of COMMON - feel free to refactor and edit when needed

#onetrust-banner-sdk.otFlat {
    z-index: $z-index-listrack-sign-on - 1 !important; // we need to set it BELOW lisTrack sign-on popup (to keep closing order)
}

.add-to-cart-messages {
    @include toast-message();
    z-index: $z-index-alert;
    top: 20%;
}

.add-to-basket-alert {
    @include toast-alert();
    z-index: $z-index-alert;
    min-width: 310px;
}

// Reset default background color for h1
h1.page-title, h1.page-title::before {
    background-color: $color-primary;
}

// override default Bootstrap's ugly slide-down-up animation that relies on height change
.collapse-fade-animation {
    position: absolute;
    top: 100%;
    left: 0;
    $duration: 0.3s;
    opacity: 0;
    transform: translateY(-30px);
    transition: opacity $duration ease-in-out, transform $duration ease-in-out;
    box-shadow: $flyout-shadow;
    padding: $spacer-20;
    // reset some .dropdown-menu default styling from Bootstrap
    float: none;
    color: $color-black;
    font-size: inherit;
    margin: 0;
    border-radius: 0;
    border: 0;

    &.show.dropdown-menu {
        opacity: 1;
        transform: translate(0);
    }

    &:not(.show) {
        display: block;
        pointer-events: none;
    }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: none;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0;
}

.image-fluid {
    width: 100%;
}

.alert {
    padding: $spacer-15 $spacer-30;

    .close {
        padding: 0 $spacer-05;
        font-size: $icon-size-xxl;
    }

    .minicart-error & {
        font-size: $fs-small;
    }
}

// fix for OneTrust popup visual issues on iOS that is caused by 3D transform-style on OneTrust's blue button
#ot-sdk-btn-floating.ot-floating-button {
    transform-style: flat !important;

    button {
        padding: 0; // center icon on iOS
    }
}
