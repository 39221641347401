.checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    .checkbox-btn {
        background: transparent;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
    }
    .checkbox-selector-container {
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid #000;
        border-radius: 20%;
        position: relative;

        & .checkbox-selector-checked {
            position: absolute;
            top: -3px;
            right: -4px;
            scale: 1.4;
        }
    }

    .checkbox-title {
        margin-left: 8px;
        font-weight: bold;
        font-size: medium;
    }
}
