.modal {
    [data-slick] {
        display: none;

        &.slick-initialized {
            display: block;
        }
    }

    .close-position {
        position: absolute;
        right: $spacer-20;
        top: 16px;
        z-index: 3;

        @include media-breakpoint-up(ls) {
            right: $spacer-25;
            top: 12px;
        }
    }

    &-body {
        @include media-breakpoint-up(ls) {
            padding: $spacer-25;
        }
    }

    &-footer {
        @include media-breakpoint-up(ls) {
            padding: $spacer-20 $spacer-25;
        }
    }

    &-header {
        min-height: 65px;
        padding-left: $spacer-20;
        padding-right: $spacer-60;

        @include media-breakpoint-up(ls) {
            padding: $spacer-20 90px $spacer-20 $spacer-25;
        }

        .close {
            // default bootstrap close style
            padding: 0;
            width: 32px;
            height: 32px;
            opacity: 1;
            position: absolute;
            margin: 0;
            transition: opacity 0.2s;
            right: $spacer-20;
            top: 16px;
            z-index: 3;

            @include media-breakpoint-up(ls) {
                width: 44px;
                height: 44px;
                right: $spacer-25;
                top: 12px;
            }

            span {
                display: none;
            }

            @include icon($fi--close-button) {
                color: inherit;
                font-size: inherit;
                display: block;
                font-size: 32px;

                @include media-breakpoint-up(ls) {
                    font-size: 44px;
                }
            }

            &:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled):hover {
                opacity: 0.6;
                animation: half-spin .2s ease 0s both;
            }
        }
    }

    &__body-clear {
        padding-top: $spacer-60;

        @include media-breakpoint-up(ls) {
            padding: 70px $spacer-25 $spacer-25;
        }
    }

    // SIZE & FIT GUIDE modal
    &-sizes {
        // .modal-sizes
        padding-right: 0!important; // overwrite bootstrap JS padding;
        border: 0;
        min-height: 100vh;
        min-height: -webkit-fill-available; // mobile safari viewport bug fix

        .modal {
            &-dialog {
                // .modal-sizes .modal-dialog
                max-width: 480px;
                width: 100%;
                float: right;
                margin: 0;
                min-height: 100vh;
            }

            &-body {
                // .modal-sizes .modal-body
                padding: $spacer-20;
                min-height: 100vh;
            }

            &-content {
                // .modal-sizes .modal-content
                height: 100%;
                min-height: 100vh;
                border: 0;
            }
        }

        &__close {
            // .modal-sizes__close
            left: $spacer-20;
            top: $spacer-20;
            position: absolute;
            z-index: 10;
        }
    }

    // YOUTUBE VIDEO modal
    &-video {
        // .modal-video
        padding-right: 0!important; // overwrite bootstrap JS padding;

        .modal-dialog {
            // .primary-video .modal-dialog
            width: 100%;
            max-width: $modal-max-width;

            @include media-breakpoint-down(md) {
                margin-top: 0;
                margin-bottom: 0;

                iframe {
                    // .primary-video .modal-dialog iframe
                    height: calc(100vh - 80px);
                }
            }
        }

        @include media-breakpoint-down(md) {
            .modal-content {
                // .primary-video .modal-content
                border: 0;
            }

            .modal-body {
                // .primary-video .modal-body
                height: 100vh;
            }
        }
    }

    // PRIMARY IMAGES modal
    &-primary-images {
        // .modal-primary-images

        &.fade {
            // .modal-primary-images.fade
            transition: opacity .0s linear;

            .modal-dialog {
                // .modal-primary-images.fade .modal-dialog
                transition: transform .0s ease-out;
                transform: none;
            }
        }

        .modal-dialog {
            // .modal-primary-images .modal-dialog
            width: 100%;
            max-width: $modal-max-width;
        }

        .modal-body {
            // .modal-primary-images .modal-body
            min-height: 200px;
        }

        .slick-img {
            // .modal-primary-images .slick-img
            max-height: calc(100vh - 210px);
            margin: 0 auto;
        }
    }

    // QUICKVIEW \ QUICKEDIT modal
    &-quick {
        // .modal-quick
        padding-right: 0!important; // overwrite bootstrap JS padding;
        border: 0;

        .modal-dialog {
            // .modal-quick .modal-dialog
            width: 100%;
            max-width: $modal-max-width;

            @include media-breakpoint-down(md) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(md) {
            .modal-content {
                // .modal-quick .modal-content
                border: 0;
            }

            .modal-body {
                // .modal-quick .modal-body
                min-height: calc(100vh - 65px);
            }
        }
    }
}
