[data-slick] {
    .video {
        &__img {
            // [data-slick] .video__img
            height: auto;
        }
    }
}

.video {
    &__tile {
        // .video__tile
        position: relative;
        display: block;

        &::after {
            position: absolute;
            width: 44px;
            height: 44px;
            top: calc(50% - 22px);
            left: calc(50% - 22px);
            margin: 0 auto;
        }

        @include icon($fi--play, $pseudo: after) {
            color: $color-black-text;
            font-size: $fs-xxl;
            text-shadow: 0 0 2px white;

            @include media-breakpoint-up(ls) {
                font-size: $fs-xxxl;
            }
        }
    }

    &__img {
        // .video__img
        object-fit: cover;
        height: 100%;
        width: 100%;
        aspect-ratio: 1 / 1;
    }
}