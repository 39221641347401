// Buttons
$btn-padding-y:                     9px !default;
$btn-padding-x:                     $spacer-20 !default;

$btn-font-size:                     $fs-base !default;

$btn-border-width:                  1px !default;

$btn-focus-width:                   0 !default;
$btn-focus-box-shadow:              none !default;

$btn-box-shadow:                    none !default;
$btn-active-box-shadow:             none !default;

$btn-link-disabled-color:           $color-gray !default;
$btn-disabled-opacity:              1 !default;

// Forms
$form-group-margin-bottom:          $spacer-20 !default;

$form-feedback-invalid-color:       $color-red !default;

$input-padding-y:                   11px !default;
$input-padding-x:                   $spacer-15 !default;

$input-placeholder-color:           $color-gray !default;

$input-bg:                          $color-white !default;

$input-font-size:                   $fs-base !default;
$input-line-height:                 $lh-xs !default;
$input-padding-y-sm:                $input-padding-y !default;
$input-padding-x-sm:                $input-padding-x !default;
$input-font-size-sm:                $input-font-size !default;
$input-line-height-sm:              $input-line-height !default;
$input-padding-y-lg:                $input-padding-y !default;
$input-padding-x-lg:                $input-padding-x !default;
$input-font-size-lg:                $input-font-size !default;
$input-line-height-lg:              $input-line-height !default;

$input-color:                       $color-black !default;

$input-border-color:                $color-gray !default;
$input-border-width:                1px !default;
$input-box-shadow:                  none !default;

$input-focus-bg:                    $input-bg !default;
$input-focus-border-color:          $input-border-color !default;
$input-focus-color:                 $input-color !default;
$input-focus-box-shadow:            none !default;

$input-placeholder-color:           $color-gray !default;

$input-height:                      auto !default;
$input-height-sm:                   $input-height !default;
$input-height-lg:                   $input-height !default;

$form-check-input-gutter:           0 !default;
