.primary-images {
    &__mobile {
        // .primary-images__mobile
        position: relative;
    }

    &-carousel {
        // .primary-images-carousel
        margin-bottom: $spacer-50;
    }

    &-grid {
        // .primary-images-grid
        display: flex;
        gap: $spacer-20;
        flex-wrap: wrap;

        &__item {
            // .primary-images-grid__item
            flex: 0 1 calc(50% - 10px);
            position: relative;
            aspect-ratio: 1/1;
        }

        &__link {
            // .primary-images-grid__link
            display: inline-block;

            &::after {
                position: absolute;
                width: 40px;
                height: 40px;
                bottom: 0;
                right: 0;
                margin: 0 auto;
                padding: 10px;
            }

            @include icon($fi--zoom-plus, $pseudo: after) {
                color: $color-black-text;
                font-size: $fs-large;
                text-shadow: 0 0 2px white;
            }
        }
    }
}

.page--planet-a{
    .primary-images {
        &-grid__item {
            // .primary-images-grid__item
            flex: 0 1 calc(50% - 10px);
            position: relative;
            aspect-ratio: unset;
        }
    }
}