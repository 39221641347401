.footer {
    background-color: $color-gray-light;
    padding-top: $spacer-25;
    padding-bottom: $spacer-20;

    @include media-breakpoint-up(ls) {
        padding-top: $spacer-60;
    }

    &-subscribe {
        // .footer-subscribe
        display: grid;
        row-gap: $spacer-20;
        text-align: center;

        @include media-breakpoint-up(ls) {
            text-align: left;
        }

        @include media-breakpoint-up(xxl) {
            row-gap: $spacer-25;
        }

        &__title {
            // .footer-subscribe__title
            font-family: $font-family-secondary;
            font-size: $fs-xl;
            line-height: $lh-base;
            font-weight: $fw-regular;
            margin-bottom: 0;

            @include media-breakpoint-up(ls) {
                font-size: 30px;
                line-height: $lh-small;
            }
        }

        &__description {
            // .footer-subscribe__description
            font-family: $font-family-primary;
            font-size: $fs-base;
            line-height: $lh-medium;
            margin-bottom: 0;
        }
    }

    &-form {
        // .footer-form
        display: grid;
        row-gap: $spacer-15;
        margin-top: $spacer-20;

        @include media-breakpoint-up(md) {
            grid-template-columns: 2fr 1fr;
            column-gap: $spacer-15;
        }

        @include media-breakpoint-up(ls) {
            grid-template-columns: 1fr;
            row-gap: $spacer-20;
        }

        @include media-breakpoint-up(xxl) {
            grid-template-columns: 2fr 1fr;
            column-gap: $spacer-20;
            margin-top: $spacer-25;
        }

        &__input {
            // .footer-form__input
            margin-bottom: 0;

            @include media-breakpoint-up(ls) {
                max-width: 350px;
            }

            @include media-breakpoint-up(xxl) {
                max-width: 100%;
            }
        }

        &__submit {
            // .footer-form__submit
            width: 100%;
            font-size: $fs-base;
            letter-spacing: 0 !important;
            height: fit-content;

            @include media-breakpoint-up(ls) {
                width: auto;
                max-width: max-content;
                padding-left: $spacer-35;
                padding-right: $spacer-35;
            }
        }
    }

    &-socials {
        // .footer-socials
        display: grid;
        justify-content: center;
        row-gap: $spacer-20;
        margin-top: $spacer-30;

        @include media-breakpoint-up(md) {
            margin-top: $spacer-20;
        }

        @include media-breakpoint-up(ls) {
            justify-content: flex-start;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: $spacer-50;
        }

        &__title {
            // .footer-socials__title
            font-family: $font-family-primary;
            font-size: $fs-large;
            line-height: $lh-base;
            font-weight: $fw-medium;
            text-align: center;
            margin-bottom: 0;

            @include media-breakpoint-up(ls) {
                text-align: left;
            }
        }

        &__list {
            // .footer-socials__list
            display: flex;
            align-items: center;
            column-gap: $spacer-20;

            @include media-breakpoint-up(md) {
                column-gap: $spacer-10;
            }

            @include media-breakpoint-up(ls) {
                column-gap: $spacer-20;
            }
        }

        &__icon {
            // .footer-socials__icon
            font-size: $fs-xxl;
            transition: $animation-default;

            &:hover {
                color: $color-primary;
            }
        }
    }

    &-accordion {
        // .footer-accordion
        margin-top: $spacer-30;

        @include media-breakpoint-up(md) {
            margin-top: $spacer-20;
        }

        @include media-breakpoint-up(ls) {
            display: flex;
            justify-content: space-between;
            column-gap: $spacer-20;
            margin-top: 0;
        }

        &__item {
            // .footer-accordion__item
            @include media-breakpoint-up(ls) {
                border: none;
                padding: 0;
                margin-bottom: 0;
            }

            &--first {
                // .footer-accordion__item--first
                border-top: 1px solid $color-gray;
                padding-top: $spacer-20;

                @include media-breakpoint-up(ls) {
                    border-top: 0;
                    padding-top: 0;
                }
            }
        }

        &__btn {
            // .footer-accordion__btn
            font-size: $fs-large;
            line-height: $lh-base;
            font-weight: $fw-medium;
            text-transform: uppercase;
            text-align: left;

            @include media-breakpoint-up(ls) {
                pointer-events: none;
            }

            &:hover.accordion__btn:hover {
                // .footer-accordion__btn:hover.accordion__btn:hover
                color: initial;
            }
        }

        &__control {
            // .footer-accordion__control
            display: flex;
            align-items: center;

            @include media-breakpoint-up(ls) {
                display: none;
            }
        }

        &__content {
            // .footer-accordion__content
            @include media-breakpoint-up(ls) {
                display: block !important;
            }
        }

        &__arrow {
            // .footer-accordion__arrow
            @include icon($fi--arrow-top) {
                color: $color-black-text;
                font-size: 8px;
            }
        }

        &__list {
            // .footer-accordion__list
            display: grid;
            row-gap: $spacer-20;

            li {
                line-height: $lh-small;
            }
        }

        &__link {
            // .footer-accordion__link
            font-family: $font-family-primary;
            font-size: $fs-small;
            color: $color-black-text;
            text-underline-offset: $spacer-05;
        }
    }

    &-copyright {
        // .footer-copyright
        font-family: $font-family-primary;
        font-size: $fs-small;
        line-height: $lh-small;
        color: $color-black-text;
        text-align: center;
        display: block;
        margin-top: $spacer-25;

        @include media-breakpoint-up(ls) {
            text-align: right;
            margin-top: $spacer-20;
        }
    }

    &-row {
        // .footer-row
        @include media-breakpoint-up(xl) {
            justify-content: space-between;
        }
    }

    .email-signup-message {
        margin-top: $spacer-10;
    }
}

.btn-back-to-top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 44px;
    height: 44px;
    border-radius: 4px;
    bottom: $spacer-15;
    right: $spacer-15;
    transition: background-color .3s,
        opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: $z-index-back-to-top;
    background-color: $color-primary;
    cursor: pointer;

    @include media-breakpoint-up(ls) {
        bottom: $spacer-30;
        right: $spacer-30;
    }

    &.showed {
        visibility: visible;
        opacity: 1;
    }
}
