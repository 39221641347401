@import "menu";

.site-header {
    position: sticky;
    top: 0;
    z-index: $z-header;
    background-color: $color-white;

    &__backdrop {
        // backdrop
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: opacity 0.3s;
        opacity: 0;
        z-index: 1;
        pointer-events: none;
        background-color: $grey-transparent-3;
        content: '';
    }

    &.is-flyout-open {
        .site-header__backdrop {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__nav {
        position: relative;
    }

}

.header {
    position: relative;

    &__content {
        height: 60px;
        display: flex;
        max-width: $container-max-width;
        padding: $spacer-10 $grid-gutter-width / 2;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        @include media-breakpoint-down(ls) {
            padding-right: 0px;
            justify-content: end;
        }

        @include media-breakpoint-up(lg) {
            position: static;
            height: 60px;
        }
    }

    .navbar-toggler {
        font-size: $icon-size-xxl;
        margin-left: -0.75rem; // compensate Bootstrap left button usability padding
    }
}

.navbar-header {
    display: flex;
    align-items: center;
    gap: $spacer-10;

    @include media-breakpoint-up(lg) {
        gap: $spacer-20;
    }

    .search {
        position: static;
    }

    .icon-search {
        font-size: $icon-size-xl;
    }
}

// color icons
.icon-bag,
.icon-user,
.icon-heart {
    @extend .icon-hover-effect;

    .is-filled & .may-be-filled {
        fill: $color-primary;
    }
}

.brand {
    width: 135px;
    display: block;

    @include media-breakpoint-down(ls) {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translate(0px, -50%);
    }

    @include media-breakpoint-up(lg) {
        width: 135px;
    }

    &__image {
        width: 100%;
        display: block;
    }
}

.header-banner {
    text-align: center;
    text-transform: uppercase;
    font-size: $fs-xs;
    letter-spacing: $ls-medium;

    .header-banner-content { // class from content markup
        background-color: #7CB2CA9A;
        padding: $spacer-15 $spacer-20;
    }
}

.minicart,
.wishlist {
    .minicart-icon-wrapper,
    .wishlist-icon-wrapper {
        position: relative;
    }

    .minicart-quantity,
    .wishlist-quantity {
        width: 100%;
        left: 0;
        bottom: $spacer-05;
        font-weight: fw(bold);
        line-height: normal;
        text-align: center;
        font-size: 12px;
        position: absolute;
        display: none;
    }

    .wishlist-quantity {
        bottom: 7px;
    }

    .is-filled .minicart-quantity,
    .is-filled .wishlist-quantity {
        display: block;
    }
}

// TODO: review parts below

// a.normal {
//     color: #{var(--skin-primary-color-1)};
//     text-decoration: underline;
// }

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: #{var(--skin-link-color-1)};
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}

.no-menu-header {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: $spacer-20;
    height: $spacer-35;
    box-sizing: content-box;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding: $spacer-10;
    }
}

// FULL WIDTH TEMPLATE //
//alterations to existing header for full-width page-designer template

.site-header {

    &.full-width {
        background-color: transparent;

        .site-header__nav {

            .header__content {
                height: 60px;
                display: flex;
                justify-content: center;

                @include media-breakpoint-down(ls) {
                    justify-content: space-between;
                    padding-right: 0px;
                }
            }

            .navbar-header {
                align-items: center;
                gap: 10px;

                .icon-search {
                    font-size: 20px;
                    color: $color-white;
                }

                #wishlist-icon {
                    display: none;
                }

                .minicart {
                    .minicart-quantity {
                        font-size: 10px !important;
                    }
                }

                .minicart-icon-wrapper svg {
                    width: 25px;
                    g {stroke: $color-white;}
                }

                .user-account svg {
                    width: 20px;
                    g {stroke: $color-white;}
                }

                .navbar-toggler {
                    font-size: 25px;
                    color: $color-white;
                }
            }

            .nav-item  {
                .nav-link {
                    @include media-breakpoint-up(lg) {
                        font-size: 14px;
                        color: $color-white;
                    }
                }
            }
        }

        .site-logo {
            height: 55px;

            .brand {
                width: 135px;
                height: 100%;

                .brand__image {
                    width: 100%;
                    height: 100%;
                    content: '';
                    background-image: url('../images/logo-white.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position-y: 50%;
                }

                @include media-breakpoint-down(ls) {
                    left: 15px;
                    transform: translate(0px, -50%) !important;
                }
            }
        }
        
        .menu-content {
            &.menu-content--lvl1 {
                background-color: $color-white !important;
            }
        }

        &.sticky {
            background-color: $color-white;                

            .site-header__nav {
                .navbar-header {

                    .icon-search {
                        color: $color-black;
                    }
    
                    .minicart-icon-wrapper svg {
                        g {stroke: $color-black;}
                    }
    
                    .user-account svg {
                        g {stroke: $color-black;}
                    }

                    .navbar-toggler {
                        color: $color-black;
                    }
                }
    
                .nav-item  {
                    .nav-link {
                        color: $color-black;
                    }
                }
            }

            .site-logo {
                .brand {
                    .brand__image {
                        background-image: url('../images/logo.svg');
                    }
                }
            }
        }

        @include media-breakpoint-up(lg){
            &:hover {
                background-color: $color-white;                

                .site-header__nav {
                    .navbar-header {
    
                        .icon-search {
                            color: $color-black;
                        }
        
                        .minicart-icon-wrapper svg {
                            g {stroke: $color-black;}
                        }
        
                        .user-account svg {
                            g {stroke: $color-black;}
                        }
    
                        .navbar-toggler {
                            color: $color-black;
                        }
                    }
        
                    .nav-item  {
                        .nav-link {
                            color: $color-black;
                        }
                    }
                }
    
                .site-logo {
                    .brand {
                        .brand__image {
                            background-image: url('../images/logo.svg');
                        }
                    }
                }
            }
        }

    }
}

#fullwidth__storepage {
    margin-top: -115px;
    
    @include media-breakpoint-down(ls) {
        margin-top: -60px;
    }
}
