// ===================================
// FONTS
// ===================================
//
// This file gathers all fonts variables.
//

// TODO: Connect the correct fonts from the brand

// Font families
// Usage: Function - ff(primary)
$font-families: (
    primary       : unquote('"Inter"'),
    secondary     : unquote('"Playfair Display"')
);

$font-family-primary:               map-get($font-families, primary);
$font-family-secondary:             map-get($font-families, secondary);

$font-color-primary:                $color-black-text;
$font-color-secondary:              $color-primary;

// Fonts
$font-family-base:                  $font-family-primary, var(--font-family-sans-serif);
$font-size-base:                    $fs-base;
$line-height-base:                  $lh-base;
$font-weight-base:                  normal;
$small-font-size:                   $fs-xs;

// Headings
$headings-margin-bottom:            $spacer-20;
$headings-font-family:              map-get($font-families, secondary), var(--font-family-sans-serif);
$headings-font-weight:              map-get($font-weights, regular);
$headings-color:                    $color-black-text;
$headings-color-alt:                $color-black-text;
$headings-line-height:              $lh-small;

$h1-font-size:                      $fs-xl;
$h2-font-size:                      $fs-large;
$h3-font-size:                      $fs-large;
$h4-font-size:                      $fs-medium;
$h5-font-size:                      $fs-base;
$h6-font-size:                      $fs-small;
