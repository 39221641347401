// ===================================
// COLORS
// ===================================
//
// This file gathers all color variables.
//
// For the rest of color let's use naming for color from here https://chir.ag/projects/name-that-color/
//

// Brand styleguide
$color-pink:                #fcd0de;
$color-main-gray:           #565656;
$color-violet:              #574480;
$color-pink-darken:         #df8ebc;
$color-pink-dark:           #c53685;

// Gift Guide colors
$color-gift-guide-red: #d9433b;
$color-gift-guide-grey: #212121;
$color-gift-guide-green: #47654F;

// Specific brand variable
$color-primary:             $color-pink;
$color-primary-alt:         $color-pink-darken; // alternative (if necessary) more contrast color to increase readability
$color-secondary:           $color-main-gray;
$color-additional:          $color-pink;

// Bootstrap colors overrides
$primary: $color-primary;
$primary-hover: $color-secondary;
$secondary: $color-secondary;
$secondary-hover: $color-primary;
$success: $color-primary-alt;
$danger: $color-red;
