.product-tile {
    .color-swatches {
        .swatch-viewport {
            width: 105px;
            height: 24px !important;
            overflow: hidden;
            text-align: center;

            .js-swatch {
                display: inline-block;

                &.hide {
                    display: none;
                }
            }
        }

        .swatch-circle {
            width: 24px;
            border-radius: 50%;

            .product-recommendations-tiles-wrapper & {
                width: 24px;
                border-radius: 50%;
            }
        }

        .swatch-slide {
            width: 34px;
            height: 24px !important;
            text-align: center;
            cursor: pointer;
            visibility: hidden;

            &.show {
                visibility: visible;
            }

            @include icon($fi--carrot-right, $pseudo: after) {
                color: $color-secondary;
                font-size: 24px;
            }

            &.prev {
                @include icon($fi--carrot-right, $pseudo: after) {
                    rotate: 180deg;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .swatch-viewport {
                width: 90px;
                height: 20px !important;
            }

            .swatch-circle {
                width: 20px !important;
            }

            .swatch-slide {
                height: 20px !important;
            }
        }
    }
}
