.search-flyout {
    width: 100%;
    box-shadow: none;
    background-color: transparent;
    pointer-events: none;
    padding: 0;
    border: 0;

    &.collapse-fade-animation {
        @include media-breakpoint-up(ls) {
            transform: translateY(-60px);
        }
    }

    .btn-close {
        position: absolute;
        top: $spacer-10;
        right: $spacer-10;

        @include media-breakpoint-up(ls) {
            .fi {
                font-size: 44px;
            }
        }
    }

    &__inner {
        position: relative;
        background-color: $color-white;
        box-shadow: $flyout-shadow;
        padding: $spacer-50 $spacer-10 $spacer-20 $spacer-10;
        text-align: center;

        @include media-breakpoint-up(ls) {
            padding: $spacer-20 $spacer-10 $spacer-60 $spacer-10;
        }
    }

    &.show &__inner {
        pointer-events: auto;
    }

    // content assets id: top-search-custom-content
    &__custom-content {
        h3 {
            font-size: $fs-large;
            text-transform: uppercase;
        }

        ul {
            font-size: $fs-base;

            @include media-breakpoint-up(ls) {
                display: flex;
                justify-content: center;
                gap: 1%;
                flex-wrap: wrap;
            }
        }

        li {
            margin-bottom: $spacer-15;

            @include media-breakpoint-up(ls) {
                width: 200px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

.site-search {
    margin-bottom: $spacer-20;

    @include media-breakpoint-up(ls) {
        margin-bottom: $spacer-25;
    }

    &__form {
        max-width: 730px;
        margin: 0 auto;
    }

    .search-field {
        padding-right: $spacer-40;
        border-radius: 0;
        border: 0;
        border-bottom: 2px solid $color-gray;

        &::-webkit-search-cancel-button {
            opacity: 0;
            pointer-events: none;
        }

        &::-ms-clear { // if we join - it won't work as browser will fail whole ruleset
            opacity: 0;
            pointer-events: none;
        }
    }

    &__btn-reset,
    &__btn-search,
    .suggestions-wrapper .suggestions {
        display: none;
    }
}
