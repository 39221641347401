/* stylelint-disable */
/*md

## Accordion

### Accordion

```html_example
<div class="accordion" id="accordionExample">
    <div class="accordion__item">
        <div class="accordion__header d-flex justify-content-between" id="item-heading-1">
            <div class="accordion__title">
                <button class="btn btn-no-min-width accordion__btn h4" type="button" data-toggle="collapse" data-target="#item-collapse-1" aria-expanded="true" aria-controls="item-collapse-1">
                    Your email
                </button>
            </div>
            <div class="accordion__control d-flex align-items-center">
                <span class="accordion__arrow" data-toggle="collapse" data-target="#item-collapse-1" aria-expanded="true" aria-controls="item-collapse-1"></span>
            </div>
        </div>

        <div id="item-collapse-1" class="accordion__content collapse show" aria-labelledby="item-heading-1">
            <div class="accordion__content-inner">
                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
            </div>
        </div>
    </div>

    <div class="accordion__item">
        <div class="accordion__header d-flex justify-content-between" id="item-heading-2">
            <div class="accordion__title">
                <button class="btn btn-no-min-width accordion__btn h4 collapsed" type="button" data-toggle="collapse" data-target="#item-collapse-2" aria-expanded="true" aria-controls="item-collapse-2">
                    Shipping
                </button>
            </div>
            <div class="accordion__control d-flex align-items-center">
                <div class="accordion__button-wrapper d-none d-ls-block">
                    <button class="btn btn-secondary">Edit</button>
                </div>
                <span class="accordion__arrow collapsed" data-toggle="collapse" data-target="#item-collapse-2" aria-expanded="true" aria-controls="item-collapse-2"></span>
            </div>
        </div>

        <div id="item-collapse-2" class="accordion__content collapse" aria-labelledby="item-heading-2">
            <div class="accordion__content-inner">
                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
            </div>
        </div>
    </div>

    <div class="accordion__item">
        <div class="accordion__header d-flex justify-content-between" id="item-heading-3">
            <div class="accordion__title">
                <button class="btn btn-no-min-width accordion__btn h4 collapsed" type="button" data-toggle="collapse" data-target="#item-collapse-3" aria-expanded="true" aria-controls="item-collapse-3">
                    Payment method
                </button>
            </div>
            <div class="accordion__control d-flex align-items-center">
                <span class="accordion__arrow collapsed" data-toggle="collapse" data-target="#item-collapse-3" aria-expanded="true" aria-controls="item-collapse-3"></span>
            </div>
        </div>

        <div id="item-collapse-3" class="accordion__content collapse" aria-labelledby="item-heading-3">
            <div class="accordion__content-inner">
                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
            </div>
        </div>
    </div>
</div>
```

*/

.accordion__title .accordion__btn:hover {
    color: initial;
}
