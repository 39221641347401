.fi {
    @include iconfonticons;
}

@each $name, $code in $icons {
    .fi--#{$name} {
        &::before {
            content: #{'"' + nth($code, 2) + '"'};
        }
    }
}

// Icon size helpers

.fi-size--base {
    font-size: $icon-size;
}

.fi-size--lg {
    font-size: $icon-size-lg;
}

.m-icon--flip {
    transform: none rtl(scaleX(-1));
}
