// ===================================
// FORM ELEMENTS
// ===================================
//
// This file gathers all form elements variables.
//

// Buttons
$btn-font-family:                   $font-family-base;
$btn-font-weight:                   normal;

$btn-border-radius:                 0;
$btn-border-radius-lg:              $btn-border-radius;
$btn-border-radius-sm:              $btn-border-radius;

// Forms
$input-font-family:                 $font-family-base;

$input-border-radius:               0;
$input-border-radius-lg:            $input-border-radius;
$input-border-radius-sm:            $input-border-radius;
