.nav-tabs {
    // .nav-tabs
    margin-bottom: $spacer-10;

    .nav-item {
        // .nav-tabs .nav-item
        font-size: $fs-base;
        text-decoration: none;
        font-weight: fw(medium);
        margin-right: $spacer-20;

        // @include media-breakpoint-up(ls) {
        //     margin-right: 0;
        // }

        &:hover,
        &.active {
            text-decoration: underline;
        }
    }
}
