// ===================================
// Devices/Media Queries | TODO: the values was added blindly -> need corrections
//

$breakpoint-xs:     0 !default;
$breakpoint-sm:     576px !default;
$breakpoint-md:     744px !default; // iPad Mini (2021)
$breakpoint-ls:     1024px !default;
$breakpoint-lg:     1200px !default;
$breakpoint-xl:     1440px !default;
$breakpoint-xxl:    1920px !default;

$grid-breakpoints: (
    xs: $breakpoint-xs,
    sm: $breakpoint-sm,
    md: $breakpoint-md,
    ls: $breakpoint-ls,
    lg: $breakpoint-lg,
    xl: $breakpoint-xl,
    xxl: $breakpoint-xxl
);

// ===================================
// Container: TODO: the values was added blindly -> need corrections
//

$container-small:       576px !default;
$container-medium:      750px !default;
$container-large:       994px !default;
$container-larger:      1120px !default;
$container-x-large:     1320px !default;
$container-xx-large:    1480px !default;

$container-max-width:   1920px !default; // cut-off for edge cases like 4k monitors etc

$container-max-widths: (
    sm: $container-small,
    md: $container-medium,
    ls: $container-large,
    lg: $container-larger,
    xl: $container-x-large,
    xxl: $container-xx-large
);

// ===================================
// Gutter
//

$grid-gutter-width: 40px !default; // for the col it's will assign for each side by 20px
