/* stylelint-disable */
/*md

## Buttons

### Default buttons state

```html_example
<button type="button" class="btn btn-primary">Add to bug</button>
<button type="button" class="btn btn-secondary">International checkout</button>
<button type="button" class="btn bgl-btn-additional">International checkout</button>
```

### Active buttons

```html_example
<button type="button" class="btn btn-primary active">Add to bug</button>
<button type="button" class="btn btn-secondary active">International checkout</button>
<button type="button" class="btn bgl-btn-additional active">International checkout</button>
```

### Disabled buttons state

```html_example
<button type="button" class="btn btn-primary" disabled>Add to bug</button>
<button type="button" class="btn btn-secondary" disabled>International checkout</button>
<button type="button" class="btn bgl-btn-additional" disabled>International checkout</button>
```

### Full width buttons state

```html_example
<button type="button" class="btn btn-primary w-100">Primary</button>
<button type="button" class="btn btn-secondary w-100">Secondary</button>
<button type="button" class="btn bgl-btn-additional w-100">Additional</button>
```

### Button like link

```html_example
<button type="button" class="btn btn-style-reset btn-link">Add to bug</button>
<button type="button" class="btn btn-style-reset btn-link-underlined">International checkout</button>
```

*/
/* stylelint-enable */

// @mixin make-btn($bg-color, $border-color, $color, $bg-color-hover, $border-color-hover, $color-hover, $with-active)

.btn:not(.btn-style-reset):not(.btn-close) {
    border: 2px solid $color-transparent;
    padding: 8px $spacer-25;
    line-height: normal
}

.btn-primary {
    @include make-btn($color-black, $color-black, $color-white, $color-gray, $color-gray, $color-white, true);

    font-weight: fw(bold);
    text-transform: uppercase;
    letter-spacing: $ls-xxl;
}

.btn-secondary,
.btn-outline-primary {
    @include make-btn($color-secondary, $color-secondary, $color-white, $color-gray, $color-gray, $color-white, true);

    font-weight: fw(bold);
    text-transform: uppercase;
    letter-spacing: $ls-xxl;
}

.bgl-btn-additional {
    @include make-btn($color-primary, $color-primary, $color-secondary, $color-white, $color-primary, $color-secondary, true);

    font-weight: fw(bold);
    text-transform: uppercase;
    letter-spacing: $ls-xxl;
}

.btn-pink {
    @include make-btn($color-pink-dark, $color-pink-dark, $color-white, $color-white, $color-white, $color-pink-dark, true);
    font-weight: fw(bold);
    text-transform: uppercase;
    letter-spacing: $ls-xxl;
}

.btn-homepage {
    @include make-btn($color-white, $color-black, $color-black, $color-black, $color-black, $color-white, true);
    font-size: .625rem;
    font-weight: 600;
    border-width: 2px;
    text-transform: uppercase;
    letter-spacing: $ls-xxl;
    border-color: black !important;
    min-width: 200px;
    width: max-content;
    font-family: 'Inter';
    @include media-breakpoint-up(sm) {
        font-size: .75rem;
        letter-spacing: 2.6px;
        font-weight: fw(bold);
    }
    @include media-breakpoint-up(xxl) {
        font-size: 1rem;
    }
}

.btn-homepage-round {
    @include make-btn($color-white, $color-black, $color-black, $color-black, $color-black, $color-white, true);
    font-size: .625rem;
    font-weight: 600;
    border-width: 2px;
    border-radius: 25px;
    text-transform: uppercase;
    letter-spacing: $ls-xxl;
    border-color: black !important;
    min-width: 165px;
    width: max-content;
    font-family: 'Inter';
    @include media-breakpoint-up(sm) {
        font-size: .75rem;
        letter-spacing: 2.6px;
        font-weight: fw(bold);
    }
    @include media-breakpoint-up(xxl) {
        font-size: 1rem;
    }
}

.btn-homepage-round-black {
    @include make-btn($color-black, $color-black, $color-white, $color-white, $color-black, $color-black, true);
    font-size: .625rem;
    font-weight: 600;
    border-width: 2px;
    border-radius: 25px;
    text-transform: uppercase;
    letter-spacing: $ls-xxl;
    border-color: black !important;
    min-width: 165px;
    width: max-content;
    font-family: 'Inter';
    @include media-breakpoint-up(sm) {
        font-size: .75rem;
        letter-spacing: 2.6px;
        font-weight: fw(bold);
        min-width: 200px;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 1rem;
    }
}

.btn-homepage-black {
    @include make-btn($color-black, $color-black, $color-white, $color-white, $color-black, $color-black, true);
    font-size: .625rem;
    font-weight: 600;
    border-width: 2px;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    border-color: black !important;
    min-width: 200px;
    width: max-content;
    font-family: 'Inter';
    @include media-breakpoint-up(sm) {
        font-size: .75rem;
        letter-spacing: 2.6px;
        font-weight: fw(bold);
    }
    @include media-breakpoint-up(xxl) {
        font-size: 1rem;
    }
}

.btn-transparent {
    @include make-btn($color-transparent, $color-black, $color-black, $color-black, $color-black, $color-white, true);
    border: 2px solid $color-black;
    padding: 8px $spacer-20;
    font-weight: fw(bold);
    text-transform: uppercase;
    letter-spacing: $ls-xxl;
}

.cta {
    font-weight: 700;
    font-family: "Inter";
    font-size: 16px;
    letter-spacing: -.48px;
    padding: 0;
    text-transform: none;
}

.cta-underline {
    font-weight: 800;
    text-decoration: underline;
    text-decoration-color: $color-pink;
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
}

.cta-underline-red {
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: $color-gift-guide-red;
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
    text-transform: uppercase;
    font-size: 24px !important;
    letter-spacing: 4.8px;
    font-family: "Barlow Condensed";
}

.btn-payment {
    border-width: 2px;
    border-style: solid;

    &-apple-native {
        border-radius: 0 !important;
    }
}

.btn-gift-guide-red {
    @include make-btn($color-gift-guide-red, $color-gift-guide-red, $color-white, $color-white, $color-gift-guide-red, $color-gift-guide-red, true);
    font-weight: fw(medium);
    border-width: 2px;
    text-transform: uppercase;
    letter-spacing: 9.6px;
    font-family: "Barlow Condensed";
}

.btn-wide {
    @include make-btn($color-black, $color-black, $color-white, $color-white, $color-black, $color-black, true);
    font-family: "Inter";
    font-weight: fw(bold);
    text-transform: uppercase;
    letter-spacing: $ls-xxl;
    max-width: 568px;
}

.btn-gradient-container {
    .b-hero-text-overlay__button-wrapper:nth-child(1) .btn-gradient--gold {
        background: linear-gradient(to right, #ecc860, #d2a010); /* Gradient from pink to orange */
        color: #262626;
        font-size: 11px;
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            min-width: 165px;
        }
        font-family: 'Inter';
        font-weight: 700;
        padding: 12px 40px;
        letter-spacing: .729px;
        min-width: 125px;
        border: none !important;
        border-radius: 0;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .b-hero-text-overlay__button-wrapper:nth-child(1) .btn-gradient--gold:hover {
        background: linear-gradient(to right, #fff2cb, #ffd457); /* Reversed gradient for hover effect */
    }

    .b-hero-text-overlay__button-wrapper:nth-child(2) .btn-gradient--gold {
        background: transparent;
        color: #ecc860;
        font-size: 11px;
        @include media-breakpoint-up(lg) {
            font-size: 14px;
            min-width: 165px;
        }
        font-family: 'Inter';
        font-weight: 700;
        padding: 10px 38px;
        letter-spacing: .729px;
        min-width: 125px;
        border: 2px solid #e0b63a;
        border-radius: 0;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .b-hero-text-overlay__button-wrapper:nth-child(2) .btn-gradient--gold:hover {
        color: #ffebb3;
        border-color: #fff7e0;
    }
}
