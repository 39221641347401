p {
    &:last-child {
        margin-bottom: 0;
    }
}

label,
.label {
    font-size: $fs-base;
    line-height: $lh-base;
    color: $color-black-text;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &.list-styled {
        list-style: outside disc;
        padding-left: $spacer-20;
    }
}

a {
    transition: $animation-default;
}

.table {
    td,
    th {
        font-size: $fs-small;
    }

    .thead-light {
        th {
            border-top: 0;
            border-bottom-width: 2px;
            border-bottom-color: $color-black;
        }
    }

    td {
        border-top: 0;
        border-bottom: 2px solid $color-gray;
    }
}
