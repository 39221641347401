.carousel {
    .icon-prev,
    .icon-next {
        background-color: $white;
        font-size: 1.875em;
        // width and height here need to use rem units because the font size used here is 30px
        height: 3rem;
        padding-top: 0.24em;
        width: 3rem;

        &::before {
            color: black;
            font-family: 'FontAwesome';
        }
    }

    .icon-prev {
        &::before {
            content: '\f104';
        }
    }

    .icon-next {
        &::before {
            content: '\f105';
        }
    }

    .carousel-control-prev {
        justify-content: flex-start;
    }

    .carousel-control-next {
        justify-content: flex-end;
    }
}

.modal .modal-body {
    flex: 0 0 auto;
}

dt {
    color: $gray-700;
    font-weight: normal;
}

.custom-checkbox {
    .custom-control-label {
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            left: -24px;
            top: 3px;
            width: 16px;
            height: 16px;
            border: 1px solid $color-gray;
            background: $color-transparent;
        }
    }

    .custom-control-input:checked ~ .custom-control-label {
        &::before {
            cursor: pointer;
            border: 1px solid $color-gray;
            background: $color-transparent;
        }

        &::after {
            cursor: pointer;
            content: '';
            position: absolute;
            top: 3px;
            left: -24px;
            display: inline-block;
            width: 5px;
            height: 9px;
            margin: 2px 0 0 6px;
            border: solid $color-gray;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }
}
