h1.page-title {
    color: $headings-color;
}

.account-dashboard .card-link.btn {
    border-radius: $radius-card;
}

.info-icon .icon {
    border-color: $color-secondary;
    color: $color-secondary;
}

.accordion__title .accordion__btn.footer-accordion__btn.btn {
    padding: 0;
}

.custom-dropdown__menu-item.is-selected {
    color: $color-black-text;
    text-decoration: underline;
}

.main-menu .navbar .menu-group {
    padding-right: 13px;
}
