// ===================================
// VARIABLES
// ===================================
//
// This file gathers all miscellaneous variables.
//
// Here will come init vars for all FE setup
// More details you can find here:
// https://app.gitbook.com/@emakina/s/picture-organic-clothing/commerce-cloud-1/technical-documentation/development-guideline/frontend-development-guideline
//

@import "colors";
@import "fonts";

// ===================================
// Labels

$label-margin-bottom: $spacer-10 !default;

// ===================================
// Opacity states
//

$opacity-00: 0 !default;
$opacity-01: .1 !default;
$opacity-02: .2 !default;
$opacity-03: .3 !default;
$opacity-04: .4 !default;
$opacity-05: .5 !default;
$opacity-06: .6 !default;
$opacity-07: .7 !default;
$opacity-08: .8 !default;
$opacity-09: .9 !default;
$opacity-10: 1 !default;

// ===================================
// Z - index TODO: compare with native bootstrap
//

$z-index-max:                   2000 !default;
$z-veil:                        1050 !default;
$z-index-alert:                 1040 !default;
$z-header:                      1030 !default;
$z-index-back-to-top:           1000 !default;
$z-index-refinement-bar:        11 !default;
$z-index-no-result-suggestion:  10 !default;
$z-index-bottom-menu:           10 !default;
$z-index-main-menu:             20 !default;
$z-index-sticky-header:         10 !default;
$z-index-dropdown:              5 !default;
$z-index-listrack-sign-on:      100001 !default; // please sync with the actual assign value by listrack to sync if changed

// ===================================
// ANIMATIONS TODO:
//

$animation-duration:                0.3s !default;
$slide-out-animation-fast:          left 0.1s linear !default;
$max-height-animation-fast:         max-height 0.5s cubic-bezier(0, 1, 0.5, 1) !default;
$animation-default:                 all $animation-duration ease !default;
$animation-bezier:                  0.3s cubic-bezier(0, 0, 0.2, 1) 0s !default;

$transition-image-scale-hover:      transform $animation-duration ease !default;
$transformation-image-scale-hover:  scale(1.05) !default;

// ===================================
// SLICK CAROUSEL
//

$slick-arrow-color:             $color-black !default;
$slick-arrow-bg-color:          transparent !default;
$slick-arrow-bg-color-hover:    transparent !default;
$slick-arrow-thickness:         2px !default;
$slick-dot-color:               transparent !default;
$slick-dot-color-hover:         $color-red !default;
$slick-dot-color-active:        $color-black !default;
$slick-opacity-default:         1 !default;
$slick-opacity-on-hover:        1 !default;
$slick-opacity-not-active:      1 !default;
$slick-arrow-transition:        all 0.1s ease !default;
$slick-border:                  $color-black !default;
$slick-font-family: inherit !important;

// ===================================
// Font icons font sizes
// ===================================

$icon-size:         18px !default;
$icon-size-lg:      22px !default;
$icon-size-xl:      26px !default;
$icon-size-xxl:     32px !default;
$icon-size-xxxl:    44px !default;

// ===================================
// Bootstrap Settings TODO: update colors and vars
// ===================================

// Body
$body-bg:                           $color-white !default;
$body-color:                        $color-black-text !default;

// Links
$link-color:                        $color-black-text !default;
$link-decoration:                   none !default;
$link-hover-color:                  $color-black-text !default;
$link-hover-decoration:             underline !default;

// Z-index
$zindex-modal:                      1050;
$zindex-modal-backdrop:             $zindex-modal - 1 !default;
$zindex-modal-close:                $zindex-modal + 1 !default;

// Tables
$table-cell-padding:                $spacer-15 !default;
$table-color:                       $color-black-text !default;
$table-border-width:                2px !default;
$table-border-color:                $color-gray !default;
$table-head-bg:                     $color-gray-light !default;
$table-head-color:                  $color-black-text !default;

// Borders
$border-width:                      1px !default;
$border-color:                      $color-black !default;
$border:                            $border-width solid $border-color !default;

// Breadcrumbs
$breadcrumb-item-padding:           $spacer-05 !default;
$breadcrumb-margin-bottom:          0 !default;
$breadcrumb-divider:                quote(">") !default;
$breadcrumb-border-radius:          0 !default;
$breadcrumb-divider-color:          $color-black-text !default;
$breadcrumb-active-color:           $color-black-text !default;
$breadcrumb-bg:                     transparent !default;

// Shadows
$flyout-shadow:                     0px 30px 30px #00000029;
$pair-it-with-shadow:               0px 0px 20px #00000029;

// Radius
$radius:                            8px;
$radius-card:                       $radius !default;

// Close
$close-font-size:                   30px !default;
$close-font-weight:                 normal !default;
$close-color:                       $color-mine-shaft !default;
$close-text-shadow:                 none !default;

// Tabs
$nav-tabs-border-width:             0 !default;
$nav-tabs-border-radius:            0 !default;
$nav-tabs-active-link-hover-bg:     transparent !default;

// TODO: fix bootstrap rem to px. Need clear.
$custom-select-feedback-icon-padding-right: 20px;
$custom-select-feedback-icon-position: center right;
$custom-select-indicator-padding: 16px;
$custom-control-indicator-size: 16px;
$nav-link-padding-y: 8px;
$custom-control-gutter: 8px;
$alert-padding-x: 32px;
