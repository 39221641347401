.price {
    font-size: $fs-large;
    font-weight: fw(bold);
    margin-bottom: $spacer-20;

    .price-container {
        display: flex;
        justify-content: left;
        gap: 15px;
        flex-wrap: wrap;
        row-gap: unset;
    }

    @include media-breakpoint-up(ls) {
        font-size: $fs-xl;
    }        

    &__old {
        // .price__old
        display: inline-flex;
        align-items: center;
        //margin-left: $spacer-10;

        .value {
            text-decoration: line-through;
        }
    }
}

.finalSale {
    font-size: 12px;
    color: $color-red;
}

// from legacy, TODO: clear
.tiered {
    color: $grey7;

    table {
        border-top: 1px solid $grey3;
        margin: 0 auto;

        tr {
            &:nth-child(odd) {
                background-color: $grey2;
            }
        }
    }

    td,
    span.price {
        font-size: 0.875rem;
        font-weight: bold;
    }

    td {
        padding: 0.313rem;

        &.quantity {
            font-weight: normal;
            text-align: right;
        }

        &.value {
            text-align: left;
        }
    }

    .table-header {
        font-size: 1.125rem;
        padding: 0.313rem;
    }

    .column-header {
        font-size: 1rem;
        padding: 0.313rem;
        font-weight: normal;
    }

    .column-header.quantity {
        text-align: right;
    }
}

