// when your lazily loaded images show their alt text and the empty image icon before loading.
img:not([src]):not([srcset]) {
    visibility: hidden;
}

// fade in effect
.lazy:not(.lazy--no-fade) {
    transition: opacity 0.3s;
    // will-change: opacity; // this causes strange issues on iOS on lazy images if popups are opened (OneTrust etc)

    &:not(.loaded) {
        opacity: 0;
    }

    &.loaded {
        opacity: 1;
    }
}

.lazy.error[data-bg] {
    background-image: url('../images/noimagemedium.png') !important;
    background-size: cover;
    opacity: 1 !important;
}

.lazy.error[data-src] {
    opacity: 1 !important;
}
