.pdp {
    &__title {
        // .pdp__title
        margin-bottom: 0px;
    }

    &__prices {
        .price {
            // .pdp__prices .price
            margin-bottom: $spacer-10;
            font-size: 18px;

            .select-size-msg {
                font-size: 13px;
                color: $color-red;
                font-weight: normal;
            }
        }
    }

    &__availability {
        // .pdp__availability
        margin-bottom: $spacer-10;

        .availability-container {
            display: flex;
            font-size: 13px;

            .availability__message, .availability-msg {
                margin-left: 3px;
            }
        }
        
    }

    &__ratings {
        // .pdp__ratings
        margin-bottom: $spacer-10;
    }

    &__sticky {
        // .pdp__sticky
        @include media-breakpoint-up(ls) {
            position: sticky;
            top: 120px;
        }

        .tab-pane ul {
            list-style-type: circle;
            padding: 0 0 0 $spacer-20; // overrided
        }
    }

    &__wishlist {
        // .pdp__wishlist
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 10;
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;

        .icon-heart {
            width: 75%;
            overflow: unset !important;
        }
    }

    .promotions {
        .promotions__title {
            font-size: 14px;
            font-family: $font-family-primary;
            font-weight: bold;
            letter-spacing: unset;
        }
        .promotions__text {
            font-size: 16px;
        }
    }

    .attribute-color {
        .color {
            font-size: 13px;
        }
    }

    .attribute-size {
        .attribute__label {
            font-size: 13px;
        }

        .size-chart {
            font-size: 13px;
            text-decoration: underline;
        }
    }

    .attribute-material {
        .attribute__label {
            font-size: 13px;
        }
    }

    #product-description-features {
        margin-top: $spacer-10;

        .accordion-header {
            font-size: 15px;
            font-family: $font-family-primary;
            font-weight: bold;
            font-style: unset !important;
            text-transform: capitalize;
            margin: 0px !important;
            padding: 15px 0px;
            cursor: pointer;
            position: relative;
            border-top: 1px solid $color-secondary;
    
            &::after {
                width: 15px;
                height: 15px;
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%) rotate(45deg);
                display: flex !important;
                align-items: center;
                justify-content: center;
                font-size: 15px !important;
            }
    
            @include icon($fi--close-icon, $pseudo: after) {
                font-size: 21px;
            }
    
            &.open {
                &::after {
                    transform: translateY(-50%) rotate(0deg);
                }
            }
        }
    
        .accordion-content {
            font-size: 14px;
            padding: 0px 0px 30px 0px;

            ul {
                list-style-type: disc !important;
                margin: 0px 0px 0px 15px !important;
            }
        }
    }

    .bv-review-highlights {
        //cons container
        .css-1cbkjb3:nth-child(2),
        .css-1uh2en9:nth-child(2),
        .css-1opbie6 {
            display: none;
        }

        //pros container
        .css-1cbkjb3 {
            width: 85%;
        }

        //pros header {
        h3.css-os6nw,
        h3.css-4s01a2 {
            display: none;
        }

        //default "show more reviews" button
        button.css-18lxqa8,
        button.css-17w92mt,
        button.css-u3wyyd,
        button.css-1268pyu
         {
            display: none;
        }
    }

    #bv-review-highlights-toggle-container {
        justify-content: center;
        display: flex;
        margin-top: 15px;
    }

    #bv-reviews-container {
        max-width: 900px;
        margin: 15px auto 0px auto;
        min-height: 400px;
        width: 100%;

        &.hide {
            display: none;
        }
    }

    .productSet-detail {
        padding-left: 0px;

        @media (min-width: 1023px) {
            padding-left: 25px;
        }
        @media (min-width: 1440px) {
            padding-left: 0px
        }
        @media (min-width: 1920px) {
            padding-left: 85px
        }
    }

    .productSetItem{
        margin-top: 50px;
        padding-left: 40px;
        padding-right: 40px;
        
        @media (min-width: 1023px) {
            margin-top: 0px;
            padding-left: 25px;
            padding-right: 20px;
        }
    }

    .horizontal-divider{
        border-top: 1px solid rgba(156, 185, 163, .4);
    }
    .productSetItem-name {
        font-size: $fs-xl;
    }

    .productSet-wrapper {
        border: 1px solid #E7E7E7;
        border-radius: 10px;
        padding: 10px 30px 0 30px;

    }

    .select-productSet-item {
        border-color: #000;
    }

    .productSet-footer {
        padding-left: 30px;
        .prices-add-to-cart-actions, .productSet-promotions {
            margin-top: 16px;
            text-align: center;
        }
    }
}
