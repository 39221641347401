.social-networks {
    display: flex;
    align-items: center;
    gap: $spacer-20;
    padding: $spacer-20 0;

    &__item {
        cursor: pointer;

        .fi {
            font-size: $icon-size-xxl;
        }
    }
}