.product-breadcrumb {
    display: none !important;
}

#desktopCarousel {
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-start;

    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.js-desktop-carousel-nav {
    width: 100%;
    height: 100%;
    max-height: 485px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-width: 70px;
    float: left;

    &::-webkit-scrollbar {
        display: none;
    }

    .primary-images-grid__item {
        margin-bottom: 5px;
        cursor: pointer;
        border-radius: 5px;
        overflow: hidden;
        position: relative;

        &.active, &:hover {
            &::before {
                opacity: 0.15;
            }
        }

        &::before {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: #000;
            opacity: 0.025;
            content: '';
            z-index: 1;
            border-radius: 5px;
        }
    }   
}

.js-desktop-carousel-container {
    display: flex;
    width: calc(100% - 85px);
    float: left;
    min-height: 500px;
    max-width: 700px;
    position: relative;
    margin-left: 15px;

}

.carousel-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    background-color: #000;
    opacity: 0.025;
    border-radius: 10px;
}

.js-desktop-carousel {
    width: calc(100%);
    text-align: center;

    .slick-list {
        border-radius: 10px;
    }

    .slick-arrow {
        font-size: 30px;
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        cursor: pointer;
        bottom: 15px;

        &.slick-arrow-left {
            right: 65px;
        }

        &.slick-arrow-right {
            right: 15px;
        }
    }

    .primary-images-grid__link {
        width: 100%;
        float: left;
        position: relative;
    }
}

.product-detail {
    .pdp__title {
        font-weight: 600;
        line-height: 32px;
    }

    .pdp__prices {
        margin-top: 15px;

        .price {
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
        }
    }

    .promotions {
        display: none !important;
    }

    .product-set-promotions {
        display: block !important;
    }

    @include media-breakpoint-down(md) {
        .product-header {
            position: relative;
        }

        .pdp__title {
           font-size: 24px;
           line-height: 32px;
        }

        .pdp__prices {
            margin-top: 5px;

            .price {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
}

.price {

    .price__main {
        &.reducedPrice {
            color: $color-red;
        }
    }
    
    .msrp {
        display: none;
    }
}

.attribute {
    &__swatch {
        border-radius: 50%;
        width: 22px;
        height: 22px;
    }

    &__btn-swatch {
        border-radius: 50%;
    }

    &__list-size {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        #A-size,
        .btn[data-attr-value="0"] {
            order: 1;
        }
        #B-size,
        .btn[data-attr-value="1"] {
            order: 2;
        }
        #C-size,
        .btn[data-attr-value="2"] {
            order: 3;
        }
        #D-size,
        .btn[data-attr-value="3"] {
            order: 4;
        }
        #E-size,
        .btn[data-attr-value="4"] {
            order: 5;
        }
        #F-size,
        .btn[data-attr-value="5"] {
            order: 6;
        }
        #G-size,
        .btn[data-attr-value="6"] {
            order: 7;
        }
        #H-size,
        .btn[data-attr-value="7"] {
            order: 8;
        }
        #I-size,
        .btn[data-attr-value="8"] {
            order: 9;
        }
        #J-size,
        .btn[data-attr-value="9"] {
            order: 10;
        }
        #K-size {
            order: 11;
        }
        #L-size {
            order: 12;
        }
        #M-size {
            order: 13;
        }
        #N-size {
            order: 14;
        }
        #O-size {
            order: 15;
        }
        #P-size {
            order: 16;
        }
        #Q-size {
            order: 17;
        }
        #R-size {
            order: 18;
        }
        #S-size {
            order: 19;
        }
        #T-size {
            order: 20;
        }
        #U-size {
            order: 21;
        }
        #V-size {
            order: 22;
        }
        #W-size {
            order: 23;
        }
        #X-size {
            order: 24;
        }
        #Y-size {
            order: 25;
        }
        #Z-size {
            order: 26;
        }
    }

    .attribute-size {
        .btn-square {
            border: 1px solid $color-main-gray !important;
            height: 35px;
            border-radius: 22.5px;
            background-color: $color-white !important;
            margin: 0px;
            padding: 0px 15px !important;
            min-height: unset;
            min-width: 45px;
            text-transform: unset;
            letter-spacing: normal;

            &.active  {
                background-color: $color-black !important;
                border-color: $color-black !important;
                opacity: 1;
                
                .size-value {
                    color: $color-white;
                }
            }
    
            &:hover:not(.active) {
                border-color: $color-gray !important;
                background-color: $color-gray !important;
                opacity: 1;

                .size-value {
                    color: $color-white;
                }
            }
    
            .size-value {
                font-size: 11px;
                color: $color-black;
                font-weight: normal;
            }
        }
    }
}

.attribute__btn-swatch {
    margin-right: 0px;
}

.pdp__availability {
    font-size: 12px;
    color: $color-red;
}

.sale-swatches {
    margin-top: $spacer-25;

    .sale-swatch-header {
        color: $color-red;
        font-size: 13px;
    }
}

#attribute-Material {
    //quickview
    display: none;
}

#productMaterials {
    margin-bottom: 30px;

    .attribute__label {
        font-size: 13px;
    }

    #showStyleMessage {
        font-weight: normal;
        display: none;

        #fabricDisplayed {
            font-style: italic;
        }

        &.show {
            display: inline-block;
        }
    }
   
    .fabrics {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    .fabric-button {
        border: 1px solid $color-main-gray;
        height: 35px;
        border-radius: 22.5px;
        color: $color-black;
        text-align: center;
        width: 30%;
        font-size: 11px;
        background-color: $color-white;
        min-width: 180px;
        //order: 1;

        &.disabled { 
            opacity: 0.3;
            //order: 2;
        }

        &.selected  {
            background-color: $color-black;
            border-color: $color-black;
            color: $color-white;
            opacity: 1;
        }

        &:hover:not(.selected) {
            border-color: $color-gray;
            background-color: $color-gray;
            color: $color-white;
            opacity: 1;
        }

        @include media-breakpoint-down(sm){
            min-width: 150px;
        }
    }

    #fabricDetails {

        .fabricDetail {
            width: 100%;
            background-color: $color-gray-light;
            float: left;
            position: relative;
            display: none;
            margin: 15px 0px;
            position: relative;

            &.show {
                display: inline-block;
            }

            .fabricImage{
                width: auto;
                height: 115px;
                float: left;
                background-color: $color-gray-lighter;

                img {
                    width: auto;
                    height: 100%;
                }
            }

            .fabricDescription {
                width: calc(100% - 130px);
                height: 115px;
                float: left;
                padding: 15px;
                font-size: 11px;
                display: flex;
                align-items: center;

                @include media-breakpoint-down(lg){
                    font-size: 10px;
                }

                @include media-breakpoint-down(md){
                    font-size: 12px;
                }

                @include media-breakpoint-down(sm){
                    font-size: 10px;
                }
            }

            .fabricDetailsClose {
                width: 15px;
                height: 15px;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                @include icon($fi--plus, $pseudo: after) {
                    font-size: 100%;
                    transform: rotate(45deg);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    #compareFabrics {
        width: 100%;
        margin-top: 10px;

        #compareFabricsButton {
            font-size: 13px;
            display: flex;
            gap: 3px;
            align-items: center;
            cursor: pointer;
            border: none;
            background-color: unset;
            
            @include icon($fi--compare, $pseudo: before) {
                font-size: 13px;
            }
        }

    }

}

.modal {
    //COMPARE FABRICS MODAL
    &-fabrics {
        padding-right: 0px !important;

        .modal-dialog {
            max-width: 800px;

            .modal-content {
                border: none;
            }
        }

        .modal-body {
            h1 {
                font-size: 24px;
                font-weight: bold;
                text-align: center;
            }

            #fabricDetails {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                gap: 15px;
                
                .fabricBox {
                    width: calc(50% - 7.5px);
                    background-color: $color-gray-light;

                    .fabricImage{
                        height: 100%;
                        width: 40%;
                        float: left;
                        background-color: $color-gray-lighter;

                        img {
                            width: 100%;
                        }
                    }

                    .fabricDescription {
                        width: 60%;
                        float: left;
                        padding: 15px;
                        font-size: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 100%;

                        h2 {
                            text-transform: unset;
                            font-size: 24px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .modalDivider {
                width: 100%;
                height: 1px;
                margin: 15px 0px;
                background-color: $color-gray-lighter;
            }

            #fabricDetailsChart {
                .chartRow {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    border-bottom: 1px solid $color-gray-lighter;

                    &:first-of-type {
                        border-top: 1px solid $color-gray-lighter;
                    }

                    &.header {
                        background-color: $color-gray-light;
                        font-weight: bold;
                    }

                    .chartCell {
                        display: flex;
                        border-right: 1px solid $color-gray-lighter;
                        height: 35px;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: 11px;
                        padding: 0px 15px;

                        &:first-of-type {
                            border-left: 1px solid $color-gray-lighter;
                            justify-content: left;
                        }

                        .checkbox {
                            width: 20px;
                            height: 20px;

                            @include icon($fi--checkmark, $pseudo: after) {
                                font-size: 12px;
                            }
                        }
                    }
                }

                #disclaimer {
                    width: 100%;
                    font-size: 9px;
                    margin-top: 15px;
                }
            }

            @include media-breakpoint-down(sm){
                #fabricDetails {
                    .fabricBox {
                        .fabricImage {
                            height: auto;
                            width: 100%;
                        }
                        .fabricDescription {
                            width: 100%;
                            height: auto;
                            font-size: 12px;

                            h2 {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(xs){
                #fabricDetails {
                    .fabricBox {
                        .fabricDescription {
                            font-size: 10px;
                        }
                    }
                }

                #fabricDetailsChart {
                    .chartRow {
                        .chartCell {
                            font-size: 10px;
                        }
                    }
                }
            }

        }

        &__close {
            // .modal-sizes__close
            right: $spacer-10;
            top: $spacer-10;
            position: absolute;
            z-index: 10;
            transform: rotate(45deg);

            .fi {
                font-size: 24px !important;
            }
        }
    }
}

.pdp { 
    #product-description-features {
        .accordion-header {
            @include icon($fi--plus, $pseudo: after) {
                font-size: 10px !important;
            }

            &.open {
                @include icon($fi--minus, $pseudo: after) {
                    font-size: 1px !important;
                } 
            }

            &::after {
                transform: translateY(-50%) rotate(0deg) !important;
                color: $color-main-gray !important;
            }
        }
    }
}

.add-to-cart-form {
    position: relative;
    margin-bottom: 30px;

    &:hover {
        .add-to-cart-form__qty {
            select.form-control {
                background-color: $color-gray;
            }
        }

        .add-to-cart-form__button-container {
            .add-to-cart {
                background-color: $color-gray;
            }
        }
    }

    .add-to-cart-form__qty {
        position: absolute;
        top: 50%;
        left: 18px;
        transform: translateY(-50%);

        select.form-control {
            padding: 13px 13px 13px 15px;
            background-position: right 23px center;
            background-size: 10px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI1IiB2aWV3Qm94PSIwIDAgOCA1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNy41MTY5NSAwLjU5NTAwMUw4LjAwMTk1IDEuMDQyTDQuMjQxOTUgNC41MDFDNC4xNzYzNSA0LjU2MDk2IDQuMDkwODMgNC41OTQ0NiA0LjAwMTk1IDQuNTk1QzMuOTEyODMgNC41OTU3NSAzLjgyNjg2IDQuNTYyMDggMy43NjE5NSA0LjUwMUwwLjAwMTk1MzEyIDEuMDQxTDAuNDg2OTUzIDAuNTk1MDAxTDQuMDA4OTUgMy44MzRMNy41MTY5NSAwLjU5NTAwMVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
            border: none;
            background-color: $color-black;
            color: $color-white;
            cursor: pointer;
        }

    }

    .add-to-cart-form__button-container {
        .add-to-cart, .add-to-cart-global {
            width: 100%;
            height: 45px;
            border-radius: 22.5px;
            max-width: unset;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
            background-color: $color-black;
            color: $color-white;
            border: none;
            text-align: center;
        }
    }
}