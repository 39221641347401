// ===================================
// COLORS
// ===================================
//
// This file gathers all color variables.
//
// For the rest of color let's use naming for color from here https://chir.ag/projects/name-that-color/
//

// Global colors styleguide
$color-black:               #000 !default;
$color-black-text:          #212121 !default;
$color-white:               #fff !default;
$color-gray:                #acacac !default;
$color-gray-lighter:        #cccccc !default;
$color-gray-light:          #f4f4f4 !default;
$color-red:                 #dd1d2d !default;
$color-green:               #007D48 !default;
$color-transparent:         transparent !default;

// Global colors from wf
$color-silver-chalice:      #a6a6a6 !default;
$color-dove-gray:           #707070 !default;
$color-mine-shaft:          #262626 !default;
$color-geyser:              #dee2e6 !default;

// Transparent RGBA
$grey-transparent-1:        rgba(0, 0, 0, 0.65) !default;
$grey-transparent-2:        rgba(0, 0, 0, 0.3) !default;
$grey-transparent-3:        rgba(0, 0, 0, 0.5) !default;
$grey-transparent-4:        rgba(0, 0, 0, 0.8) !default;

// Swatch-colors
$swatch-beige:              #aa9e8d;
$swatch-black:              black;
$swatch-blue:               blue;
$swatch-brown:              #783201;
$swatch-green:              green;
$swatch-grey:               #8f979d;
$swatch-navy:               navy;
$swatch-orange:             orange;
$swatch-pink:               #fe249a;
$swatch-purple:             purple;
$swatch-red:                red;
$swatch-white:              #ffffff;
$swatch-yellow:             #ffff00;
$swatch-clear:              #efefef;

// Bootstrap colors overrides
// $primary: $color-primary;
// $primary-hover: $color-secondary;
// $secondary: $color-secondary;
// $secondary-hover: $color-primary;
// $success: $color-primary;
// $danger: $color-red;
