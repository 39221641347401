@charset "UTF-8";

@import "~base/variables";
@import "~base/skin/skinVariables";
// @import "~base/bootstrapOverrides";
@import "~base/utilities/responsiveUtils";
// @import "font-awesome/scss/font-awesome";
@import "flag-icon";
// @import "~base/components/common";
// @import "~base/components/header";
// @import "~base/components/footer";
// @import "~base/components/hero";
@import "~base/components/headers";
@import "~base/components/notification";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=DM+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url(https://fonts.googleapis.com/css?family=Dosis&subset=latin-ext);
// @import "~base/components/pricing";
// @import "~base/components/productTiles";
@import "~base/components/tabs";
// @import "~base/components/breadcrumb";
@import "~base/components/spinner";
// @import "~base/miniCart";
// @import "~base/components/tooltip";
// @import "~base/components/headerSearch";
