$breakpoint-megamenu: 'ls' !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-megamenu);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);
$megamenu-gap: $spacer-40;

.main-menu {
    @include media-breakpoint-down($breakpoint-megamenu) {
        position: fixed;
        left: -100%;
        top: 0;
        height: 100vh;
        height: -webkit-fill-available; // iOS fix
        width: 100%;
        transition: transform 0.3s ease-in-out;
        display: block;
        background-color: $color-white;
        z-index: 1;

        &.is-active {
            transform: translateX(100%);
        }
    }

    @include media-breakpoint-only(ls) {
        left: -50%;
        width: 50%;
    }

    .btn-close {
        margin-left: auto;
    }

    .navbar,
    .nav-item {
        position: static;
    }

    & > .container,
    & > .container > .row {
        @include media-breakpoint-down($breakpoint-megamenu) {
            height: 100%;
        }
    }

    .navbar {
        padding: 0;

        @include media-breakpoint-down($breakpoint-megamenu) {
            padding: $spacer-20;
            height: 100%;
            flex-wrap: nowrap;
            flex-direction: column;
        }

        .menu-group {
            @include media-breakpoint-down($breakpoint-megamenu) {
                flex-grow: 1;
                overflow-x: hidden;
                overflow-y: auto;
                width: calc(100% + $spacer-10);
                margin-right: -$spacer-10; // reserved for scrollbar
                padding-right: $spacer-10; // reserved for scrollbar
            }
        }
    }

    .navbar-nav .nav-link {
        padding: $spacer-10 0;
        font-size: $fs-small;
        text-transform: uppercase;

        @include media-breakpoint-down($breakpoint-megamenu) {
            @include accordion-arrow($fi--arrow-bottom);

            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            &::before {
                transition: transform 0.2s;
                font-size: 15px;
                line-height: 15px;
            }
        }

        @include media-breakpoint-up($breakpoint-megamenu) {
            font-size: $fs-small;
            padding: $spacer-25 $spacer-10;
        }

        @include media-breakpoint-up(xl) {
            font-size: $fs-small;
            padding: $spacer-20 $spacer-15;
        }
    }

    .nav-item {
        text-underline-offset: $spacer-05;
        margin: 0;

        @include media-breakpoint-down($breakpoint-megamenu) {
            border-bottom: 1px solid $color-geyser;

            &:first-child > .nav-link:focus-visible { // fix random iOS mobile issue
                box-shadow: none;
            }
        }

        &.show .nav-link::before {
            transform: rotate(180deg);
        }

        &:hover,
        &:active {
            text-decoration: underline;

            @include media-breakpoint-down($breakpoint-megamenu) {
                text-decoration: none;
            }
        }
    }
}

.mobile-service-menu {
    font-size: $fs-large;
    text-transform: uppercase;
    margin-top: $spacer-10;
    padding-bottom: $spacer-40;

    &__item {
        padding: $spacer-05 0;
    }

    &__link {
        display: flex;
        align-items: center;
        gap: $spacer-10;

        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    &__support-info {
        text-transform: none;
        padding: $spacer-10 0 0 $spacer-40;
        font-size: $fs-base;
    }

    .fi {
        font-size: $icon-size-xl;
    }

    .fi,
    svg {
        width: 30px;
        text-align: center;
    }
}

.menu-content {
    &.menu-content--lvl1 {
        border: 0;
        border-radius: 0;

        @include media-breakpoint-down($breakpoint-megamenu) {
            margin: 0 $spacer-45 $spacer-15 $spacer-15;
            display: none;
        }

        @include media-breakpoint-up($next-breakpoint) {
            position: absolute;
            right: 0;
            left: 0;
            top: 100%;
            display: flex;
            justify-content: center;
            gap: $megamenu-gap;
            margin-top: -$spacer-15;
            padding: $megamenu-gap;
            background-color: #efefef;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s;
            z-index: 1000;
        }

        &.show {
            display: flex;

            @include media-breakpoint-up($next-breakpoint) {
                pointer-events: auto;
                opacity: 1;
            }
        }
    }

    &.menu-content--lvl2 {
        .menu-content__link {
            font-size: $fs-small;

            @include media-breakpoint-down($breakpoint-megamenu) {
                padding: $spacer-10 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include icon($fi--carrot-right, $pseudo: after) {
                    color: $color-primary-alt;
                    font-size: 21px;
                }

                &:hover,
                &:active {
                    text-decoration: none;
                }
            }

            @include media-breakpoint-up($next-breakpoint) {
                display: inline-block;
                padding: $spacer-05 0;
            }
        }
    }

    &__link {
        &--has-subcats {
            @include media-breakpoint-up($next-breakpoint) {
                font-size: $fs-small;
                color: inherit;
                letter-spacing: $ls-medium;
                font-weight: $fw-medium;
                text-transform: uppercase;
                margin-bottom: $spacer-15;
                pointer-events: none; // on live site these links are static texts (remove later if necessary)
            }
        }
    }

    &__banner {
        @include media-breakpoint-up($next-breakpoint) {
            margin-left: -$megamenu-gap; // compensate gap by default to center content if no slot content assign
            max-width: min(26vw, 360px);
            // max-height: 40vh;
            overflow: hidden;

            > div {
                padding-left: $megamenu-gap; // add some margin back if there's content
                height: 100%; // make sure content can fit wrapper's height
            }
        }

        .header-category-bnr {
            &__image {
                max-height: 100%;
            }
        }
    }

    @include media-breakpoint-down($breakpoint-megamenu) {
        .menu-content__link--has-subcats,
        .menu-content__link {
            margin: 0px;
            padding: 15px 0px;
            text-transform: capitalize;
            font-family: $font-family-primary;
            font-size: $fs-small;

            @include media-breakpoint-down($breakpoint-megamenu) {

                @include icon($fi--carrot-right, $pseudo: after) {
                    color: $color-primary-alt;
                    font-size: 21px;
                }

                padding: $spacer-10 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover,
                &:active {
                    text-decoration: none;
                }
            }
        }

        .menu-content__link--has-subcats {
            @include media-breakpoint-down($breakpoint-megamenu) {
                @include icon($fi--carrot-right, $pseudo: after) {
                    rotate: 90deg;
                }
            }
        }
    
        .menu-content--lvl2 {
            margin: 0px 0px 30px 30px;
            display: none;
        }
    }
}
