@import "~core/utilities/swatch";

.product-tile {
    border: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    [data-bv-show="inline_rating"] {
        margin: 0 auto .625rem;
        height: 24px;
        display: none;
    }

    &__flags {
        display: none;

        @include media-breakpoint-up(ls) {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color-mine-shaft;
            color: $color-white;
            padding: 0 1rem;
            border-bottom-right-radius: 1rem;
            border-color: $color-mine-shaft;
            line-height: 2rem;
            font-size: $fs-small;
            font-weight: $fw-bold;
            text-transform: uppercase;

        }
    }

    .quickview {
        position: absolute;
        top: $spacer-10;
        right: $spacer-10;
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        @include media-breakpoint-down(md){
            width: 20px;
            height: 20px;
        }

        .icon-search {
            width: 18px;
        }

        &:hover {
            text-decoration: none !important;
        }
    }

    .tile-body {
        padding: $spacer-15 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .color-swatches {
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: $fs-xs;
        gap: $spacer-05;

        @include media-breakpoint-up(lg) {
            gap: $spacer-15;
        }

        a:hover {
            text-decoration: none;
        }

        .swatch-circle {
            // $white will be replaced by color image background via Javascript
            @include swatch(38px, $white);

            border: 0;

            @include media-breakpoint-up(md) {
                width: 60px;
                border-radius: 12px;
            }

            .product-recommendations-tiles-wrapper & {
                width: 33px;

                @include media-breakpoint-up(md) {
                    width: 52px;
                }

                @include media-breakpoint-up(xl) {
                    width: 60px;
                }
            }

            &.is-disabled {
                opacity: 0.2;
                cursor: not-allowed;
                filter: grayscale(1);
            }
        }

        &__more {
            max-width: 3.5em;
            text-align: center;

            @include media-breakpoint-down(md) {
                max-width: 3em;
                font-size: $fs-xxs;
            }
        }
    }

    .price {
        font-size: 12px;
        margin-bottom: $spacer-20;
        text-align: center;

        .price-container {
            justify-content: center !important;
        }

        @include media-breakpoint-up(md) {
            font-size: $fs-medium;
        }
    }

    .pdp-link {
        line-height: 16px;
        font-size: $fs-xs;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: $spacer-15;
        flex-grow: 1;

        a {
            align-self: center;
            padding: 0 $spacer-10;

            @include media-breakpoint-up(md) {
                line-height: 18px;
                font-size: $fs-small;
            }
        }
    }

    .image-container {
        position: relative;
        overflow: auto;
        aspect-ratio: 1/1; // avoid layout shift

        a {
            display: block;

            .tile-image {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &.tile-image-wrapper {
                max-height: 100%;
                height: 100%;
            }
        }
    }

    .wishlist-tile {
        position: absolute;
        top: $spacer-10;
        right: 50px;
        width: 40px;
        height: 40px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        transition: opacity 0.3s;

        @include media-breakpoint-up(lg){
            opacity: 0;

            &.is-filled {
                opacity: 1;
            }
        }

        @include media-breakpoint-down(md){
            right: 40px;
            width: 20px;
            height: 20px;
        }

        .icon-heart {
            width: 18px !important;
        }
    }

    &:hover {
        .wishlist-tile {
            opacity: 1;
        }
    }

    .tile-body-footer {
        align-items: center;
        justify-content: center;
        display: flex;

        .plp-bwpBadge-container {
            width: 100%;
            .buy-with-prime-badge {
                text-align: center;
            }
        }
    }
}

.page--planet-a{
    .product-tile {
        @include media-breakpoint-up(sm){
            .wishlist-tile {
                top: $spacer-35;
            }
            .quickview {
                bottom: $spacer-30;
            }   
        }
    }
}