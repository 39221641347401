.carousel-a-plus-container {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-a-plus {
    position: relative;
    padding-bottom: 2rem;
    overflow: hidden;
    touch-action: pan-y; /* Prevents vertical scrolling during swipe */

    .progress-bar-container {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 10px;
        background: #f4f4f4;
    }
    
    .progress-bar {
        height: 100%;
        background: #f5b1b2;
        width: 0;
        transition: width 0.5s ease-in-out;
    }
}

.carousel-a-plus-inner {
    display: flex;
    transition: transform 0.5s ease;
}

.carousel-a-plus-item {
    min-width: 100%;
    box-sizing: border-box;
}

.carousel-a-plus-item img {
    width: 100%;
    display: block;
}

.carousel-a-plus-button {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 24px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    transform: translateY(-50%); 
    text-align: center;
    padding: 0;
}

.carousel-a-plus-button.prev {
    left: 10px;
}

.carousel-a-plus-button.next {
    right: 10px;
}
