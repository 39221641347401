// ===================================
// This file gathers cybersource styles from cartridge(s) providers
//

.info-icon {
    &:hover .tooltip {
        display: block;
    }

    .tooltip {
        display: none;
    }
}
