.sizes {
    &__title {
        &-main {
            // .sizes__title-main
            padding-left: $spacer-40;
            padding-right: $spacer-40;
            text-align: center;
            min-height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: $ls-large;
            font-weight: fw(bold);
            text-transform: uppercase;
            font-size: $fs-medium;
            margin-bottom: $spacer-20;

            @include media-breakpoint-up(ls) {
                padding-left: $spacer-50;
                padding-right: $spacer-50;
                min-height: 44px;
            }
        }

        &-small {
            // .sizes__title-small
            text-align: center;
            font-size: $fs-medium;
            margin-bottom: $spacer-30;
            font-weight: fw(medium);
        }

        &-mini {
            // .sizes__title-mini
            font-weight: fw(medium);
            text-transform: uppercase;
        }
    }

    &__desc {
        // .sizes__desc
        text-align: center;
        margin-bottom: $spacer-30;
        padding-bottom: $spacer-30;
        border-bottom: 1px solid $color-gray;
    }

    &__table {
        // .sizes__table
        margin-bottom: $spacer-30;
        padding-bottom: $spacer-15;
        border-bottom: 1px solid $color-gray;
    }

    &__bottom {
        // .sizes__bottom
        text-align: center;
    }
}