.quick-view-dialog {
    width: 100%;
    max-width: $modal-max-width;

    .size-chart {
        display: none;
    }
    .productSetItem {
        padding-left: 0px;
        padding-right: 0px;

        @media (min-width: 1023px) {
            padding-left: 25px;
            padding-right: 20px;
        }
    }
}
