// ===================================
// ANIMATIONS
// ===================================
//

// Spin

$animation-half-spin: half-spin 0.2s ease 0s both;

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

// Half spin
@keyframes half-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}
