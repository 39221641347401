@mixin accordion-arrow($icon: $fi--arrow-top) {
    @include icon($icon) {
        color: $color-primary-alt;
        font-size: 15px;
        line-height: 15px;

        @include media-breakpoint-up(ls) {
            font-size: 22px;
            line-height: 20px;
        }
    }
}

.accordion {
    // global
    &__item {
        margin-bottom: $spacer-20;
        padding-bottom: $spacer-20;
        border-bottom: 1px solid $color-gray;

        @include media-breakpoint-up(ls) {
            margin-bottom: $spacer-30;
            padding-bottom: $spacer-30;
        }
    }

    // heading
    &__title {
        .accordion__btn {
            padding: 0;
            margin: 0;
            text-align: left;

            &:hover {
                color: $color-primary-alt;
            }
        }
    }

    &__button-wrapper {
        height: 20px;
        margin-top: -24px;
    }

    &__arrow {
        @include accordion-arrow;

        display: block;
        margin-left: $spacer-25;
        cursor: pointer;

        &.collapsed {
            transform: rotate(180deg);
        }
    }

    &__control {
        margin-left: $spacer-20;
    }

    // inner
    &__content-inner {
        margin-top: $spacer-20;
    }
}
