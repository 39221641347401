.comparison-chart-left-js,
.comparison-chart-right-js {
  font-size: 36px;
  font-weight: bold;
  background-color: #F5F5F5;
  border-radius: 50%;
}

#comparison-chart-container {
    overflow: scroll;
    scroll-snap-type: both mandatory;

    .name-column {
        scroll-snap-align: end;
    }

    tr {
        background-color: white;
    }
  table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: white;
  }

    .bag-name {
        background-color: #F5F5F5;
        text-align: center;
    }
    .img-row {
        border: none;
    }
    .img-row th{
        border: none;
    }
    
    tr, td, th {
        border: 1px solid #C5C6C6;
    }
    .table>thead>tr>th:first-child{
        min-width: 100px;
    }
    .table>thead>tr>.name-column{
        vertical-align: middle;
        text-align: center;
        min-width: 200px;
    }
    .table>tbody>tr>td{
        border-top: unset;
        border-bottom: unset;
        vertical-align: middle;
        text-align: center;
    }

    @include media-breakpoint-up(ls) { 
        .table>thead>tr>.name-column{
            min-width:auto;
        }
    }
}