.product-line-item {
    display: flex;
    margin-bottom: $spacer-15;
    padding-bottom: $spacer-15;
    border-bottom: 1px solid $color-gray-lighter;

    &--minicart {
        // .product-line-item--minicart
    }

    &__image-wrapper {
        // .product-line-item__image-wrapper
        --image-width: 107px;

        flex: 1 0 var(--image-width);
        margin-right: $spacer-20;
        text-align: center; // center small image (i.e. no image found)

        .product-line-item--cart &,
        .product-line-item--wishlist & {
            @include media-breakpoint-up(ls) {
                --image-width: 160px;
                margin-left: $spacer-20;
            }

            @include media-breakpoint-up(lg) {
                --image-width: 200px;
                margin-right: $spacer-50;
            }
        }

        .product-line-item--co & {
            @include media-breakpoint-up(ls) {
                --image-width: 200px;
                margin-right: $spacer-05;
            }
        }

        .checkout-order-summary & {
            @include media-breakpoint-only(md) {
                --image-width: 80px;
            }

            @include media-breakpoint-up(ls) {
                --image-width: 160px;
                margin-right: $spacer-05;
            }
        }
    }

    &__top-line {
        // .product-line-item__top-line
        display: flex;
        justify-content: space-between;
        width: 100%;

    }

    &__control {
        // .product-line-item__control
        display: flex;
        justify-content: flex-end;
        width: 75px;
        margin-left: $spacer-20;

        .product-line-item--cart &,
        .product-line-item--wishlist & {
            position: absolute;
            top: 0;
            right: 0;
        }

        .product-line-item--wishlist & {
            width: 32px;
        }

        .fi {
            // .product-line-item__control .fi
            font-size: $icon-size-xxl;
        }
    }

    &__title {
        // .product-line-item__title
        margin-bottom: $spacer-10;

        .product-line-item--cart &,
        .product-line-item--wishlist & {
            margin-right: $spacer-70;
        }
    }

    &__one-attribute,
    .line-item-attributes {
        // .product-line-item__one-attribute
        margin-bottom: $spacer-10;
    }

    &__attributes {
        // .product-line-item__attributes
        margin-bottom: $spacer-10;
    }
    
    &__content {
        // .product-line-item__content
        position: relative;
        font-size: $fs-small;
        width: 100%;
    }

    &__wishlist {
        // .product-line-item__wishlist
        svg {
            // .product-line-item__wishlist svg
            margin-top: 2px;
            width: 21px;
        }
    }

    &__promo {
        // .product-line-item__promo
        color: $color-primary-alt;
        text-transform: uppercase;
        margin-bottom: $spacer-10;
        font-weight: fw(medium);
    }

    &__prices {
        // .product-line-item__prices
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 15px;

        .product-line-item--co & {
            @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg){
                flex-direction: column;
                align-items: flex-start;
                gap: $spacer-10;
            }
        }
    }

    &__qty-wrapper {
        // .product-line-item__qty-wrapper
        .quantity-form {
            // .product-line-item__qty-wrapper .quantity-form
            margin-bottom: 0;
        }

        select {
            // .product-line-item__qty-wrapper select
            min-width: 65px;
        }

        .product-line-item--co & {
            .line-item-pricing-info {
                display: flex;
                gap: $spacer-05;
            }
        }
    }


    &__cost {
        // .product-line-item__cost
        .price {
            // .product-line-item__cost .price
            font-size: $fs-small;
            margin-bottom: 0;

            .product-line-item--cart & {
                @include media-breakpoint-up(ls) {
                    font-size: $fs-large;
                }
            }
        }

        .price__old {
            // .product-line-item__cost .price__old
            //margin-right: $spacer-10;
        }
    }

    &__one-price {
        // .product-line-item__one-price
        display: flex;
    }

    &__one-price-label {
        // .product-line-item__one-price-label
        margin-right: $spacer-10;
        width: 40px;
        display: inline-block;
    }

}
